import { JobDescriptionSection, JobFormData } from "@shared/job-description-models";
import { AnswerOptions, UserInfo, UserSopAnswer } from "@shared/models";
import axios from "axios";

import { AppConfig } from "./app-config-service";

export async function getUserInfo(): Promise<UserInfo> {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/user-info`);
  return data;
}

interface UpsertSopAnswerParams extends AnswerOptions {
  questionId: string;
}

export async function upsertSopAnswer({ questionId, answer, reportAnswer }: UpsertSopAnswerParams): Promise<void> {
  return await axios.post(`${AppConfig.apiUrl}/api/sop-answers`, { questionId, answer, reportAnswer });
}

export async function getSopAnswers(): Promise<UserSopAnswer[]> {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/sop-answers`);
  return data;
}

export async function deleteAllUserSopAnswers(): Promise<void> {
  return await axios.delete(`${AppConfig.apiUrl}/api/sop-answers`);
}

export async function upsertReportAnswers(
  reportAnswers: { questionId: string; reportAnswer: string | object }[]
): Promise<void> {
  return await axios.post(`${AppConfig.apiUrl}/api/sop-report-answers`, { reportAnswers });
}

interface UploadPdfResponse {
  equipment: string[];
}

export async function processPdf(file: File): Promise<string[]> {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axios.post<UploadPdfResponse>(
    `${AppConfig.apiUrl}/api/files/process-pdf/equipment`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return data.equipment;
}

export async function generateJobDescriptionSection(
  section: JobDescriptionSection,
  fullContext: JobFormData
): Promise<string> {
  const response = await axios.post(
    "/api/job-description/gen-ai",
    {
      section: section.title,
      content: section.content,
      fullContext: fullContext
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return response.data.content;
}
