import { Button } from "@chakra-ui/react";

import { HandbookFormData } from "../types";
import { HEALTH_INSURANCE_OPTIONS, INCIDENT_REPORTING_OPTIONS, TRADE_SECRET_OPTIONS } from "./constants";

export const AdminQuickFillButton = ({
  setValue
}: {
  setValue: (name: keyof HandbookFormData, value: HandbookFormData[keyof HandbookFormData]) => void;
}) => {
  return (
    <Button
      onClick={() => {
        setValue(
          "company-culture-q",
          "Our restaurant is committed to creating a welcoming and inclusive environment where both our team members and guests feel valued. We believe in fostering creativity, teamwork, and a passion for exceptional food and service."
        );
        setValue("fte-hours-q", 32);
        setValue(
          "pay-schedule-q",
          "Employees are paid bi-weekly on Fridays, with the pay period running from Monday through Sunday of the previous two weeks."
        );
        setValue("tip-credit-q", true);
        setValue("payment-method-q", "Direct Deposit Only");
        setValue("offers-pto-q", true);
        setValue(
          "pto-allocation-q",
          "PTO accrues based on length of service: two weeks for the first 5 years, three weeks through year 10, and four weeks thereafter. PTO accrues in our payroll system throughout the 52 weeks of the year."
        );
        setValue("pto-rollover-q", "PTO will roll over one calendar year");
        setValue(
          "health-insurance-policy",
          HEALTH_INSURANCE_OPTIONS[Math.floor(Math.random() * HEALTH_INSURANCE_OPTIONS.length)]
        );
        setValue("insurance-enrollment-dates", "October 15th through November 15th");
        setValue("insurance-start-date", "January 1st of each year");
        setValue(
          "employee-discount",
          "Employees receive a 50% discount on meals during their shifts and 25% discount when dining in during off-duty hours."
        );
        setValue("closed-holidays", "We are closed on New Year's Day, Easter, Thanksgiving, and Christmas Day.");
        setValue("offers-floating-holiday", true);
        setValue(
          "floating-holiday-params",
          "Employees receive one paid floating holiday per year that can be used for religious or cultural observances. This must be scheduled at least two weeks in advance and does not roll over to the next year."
        );
        setValue("offers-bereavement", true);
        setValue(
          "bereavement-policy",
          "Employees receive up to 3 paid days off for immediate family members (spouse, children, parents, siblings) and 1 day for extended family. Pay is based on scheduled shifts during the bereavement period."
        );
        setValue("offers-parental-leave", true);
        setValue(
          "parental-leave-policy",
          "In addition to FMLA, we offer 2 weeks of paid parental leave for both primary and secondary caregivers. Employees may also use accrued PTO to extend their leave."
        );
        setValue("offers-401k", true);
        setValue(
          "401k-policy",
          "Employees are eligible to participate in our 401(k) plan after 6 months of employment. We offer a 3% match on employee contributions."
        );
        setValue("offers-life-insurance", true);
        setValue(
          "life-insurance-details",
          "Basic life insurance is provided through MetLife. Coverage is 1x annual salary. Visit www.metlife.com or contact HR for enrollment details."
        );
        setValue(
          "performance-evaluations",
          "Performance evaluations are conducted annually in December. Additional reviews may be scheduled as needed for new employees or performance concerns."
        );
        setValue(
          "boh-uniform",
          "Back of House employees must wear black non-slip shoes, black pants, and a clean chef coat provided by the restaurant. Hair must be covered with a hat or hair net."
        );
        setValue(
          "foh-uniform",
          "Front of House employees must wear black non-slip shoes, black pants, a black button-down shirt, and a black apron provided by the restaurant. Hair must be neatly groomed and pulled back if longer than shoulder length."
        );
        setValue(
          "call-in-policy",
          "Employees must call their manager at least 4 hours before their scheduled shift. Text messages are not acceptable. For illness-related absences of 3 or more days, a doctor's note is required upon return."
        );
        setValue(
          "time-off-request",
          "Time off requests must be submitted through our scheduling software at least two weeks in advance. Requests are approved on a first-come, first-served basis, considering business needs."
        );
        setValue("drug-tests", ["Pre-employment", "Post Accident", "Reasonable Suspicion"]);
        setValue("termination-grounds", [
          "Falsification of personal records or company documents",
          "Violence or weapons in the workplace",
          "Bullying in-person or digitally",
          "Absence without notification for three consecutive days",
          "Creating an unsafe work environment by any means, whether it be physically, mentally, emotionally, financially",
          "Theft",
          "Insubordination",
          "Violation of the harassment policy outlined in this handbook"
        ]);
        setValue("trade-secret-policy", TRADE_SECRET_OPTIONS[Math.floor(Math.random() * TRADE_SECRET_OPTIONS.length)]);
        setValue("uses-electronic-signatures", false);
        setValue("posts-marketing-photos", false);
        setValue(
          "cell-phone-policy",
          "Cell phones must be kept in lockers or break areas during shifts. They may be used during designated breaks in non-customer areas. Emergency calls should be directed to the restaurant's main line."
        );
        setValue("media-inquiry-policy", "Media inquiries should be routed to the owner");
        setValue("uses-company-vehicles", true);
        setValue("requires-personal-vehicles", false);
        setValue("serves-alcohol", true);
        setValue(
          "incident-reporting",
          INCIDENT_REPORTING_OPTIONS[Math.floor(Math.random() * INCIDENT_REPORTING_OPTIONS.length)]
        );
      }}
    >
      Quick Fill
    </Button>
  );
};
