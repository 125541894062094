import { FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Input, Text, VStack } from "@chakra-ui/react";
import { Control, Controller, UseFormWatch } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";
import { HEALTH_INSURANCE_OPTIONS, PTO_ROLLOVER_OPTIONS } from "./constants";

interface SectionProps {
  control: Control<HandbookFormData>;
  setValue: (name: keyof HandbookFormData, value: HandbookFormData[keyof HandbookFormData]) => void;
  watch: UseFormWatch<HandbookFormData>;
}

export const EmployeeBenefitsSection = ({ control, setValue, watch }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Employee Benefits</Heading>
      {/* PTO Questions */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be offering paid time off (PTO)?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-pto-q"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-pto-q") && (
        <>
          {/* PTO Allocation Question */}
          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                How will the PTO be allocated?
              </Heading>
            </FormLabel>
            <Text fontSize="sm" color="blue.400" mb={4}>
              Consider factors like employee type (salary/hourly), years of service, and accrual method.
            </Text>
            <Controller
              name="pto-allocation-q"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ""}
                  placeholder="e.g., Two weeks for first 5 years, accrues throughout the year..."
                />
              )}
            />
          </FormControl>
          {/* PTO Rollover Question */}
          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                What is your PTO rollover policy?
              </Heading>
            </FormLabel>
            <Controller
              name="pto-rollover-q"
              control={control}
              render={({ field }) => (
                <VStack align="stretch" spacing={2}>
                  <RadioGroup
                    onChange={(value) => {
                      if (value === "Other") {
                        setValue("pto-rollover-q", "");
                      } else {
                        setValue("pto-rollover-q", value);
                      }
                    }}
                    value={field.value === "" ? "Other" : field.value}
                    colorScheme="brand"
                  >
                    <Stack direction="column" spacing={3}>
                      {PTO_ROLLOVER_OPTIONS.map((option) => (
                        <Radio key={option} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                  {field.value === "" && (
                    <Input
                      value={field.value}
                      onChange={(e) => setValue("pto-rollover-q", e.target.value)}
                      placeholder="Enter custom PTO rollover policy..."
                    />
                  )}
                </VStack>
              )}
            />
          </FormControl>
        </>
      )}
      {/* Health Insurance Questions */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Please select your health insurance policy:
          </Heading>
        </FormLabel>
        <Controller
          name="health-insurance-policy"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value} colorScheme="brand">
              <Stack direction="column" spacing={3}>
                {HEALTH_INSURANCE_OPTIONS.map((option) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("health-insurance-policy") && !watch("health-insurance-policy").startsWith("We will not be") && (
        <>
          {/* Open Enrollment Dates */}
          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                What are your open enrollment dates for health insurance?
              </Heading>
            </FormLabel>
            <Controller
              name="insurance-enrollment-dates"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ""}
                  placeholder="e.g., October 15th through November 15th"
                />
              )}
            />
          </FormControl>

          {/* Insurance Start Date */}
          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                When does the new insurance period start?
              </Heading>
            </FormLabel>
            <Controller
              name="insurance-start-date"
              control={control}
              render={({ field }) => (
                <TextInput {...field} value={field.value || ""} placeholder="e.g., January 1st of each year" />
              )}
            />
          </FormControl>
        </>
      )}

      {/* Employee Discount */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your employee discount?
          </Heading>
        </FormLabel>
        <Controller
          name="employee-discount"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value || ""}
              placeholder="e.g., 50% off during shifts, 25% off when dining in..."
            />
          )}
        />
      </FormControl>

      {/* Holiday Information */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What holidays or days will you be closed?
          </Heading>
        </FormLabel>
        <Controller
          name="closed-holidays"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value || ""}
              placeholder="List the holidays your restaurant will be closed..."
            />
          )}
        />
      </FormControl>

      {/* Floating Holiday */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Do you want to add a floating holiday off for those who might observe different holidays?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-floating-holiday"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-floating-holiday") && (
        <FormControl>
          <FormLabel fontWeight="bold">
            <Heading size="md" as="span">
              What are the parameters for the floating holiday?
            </Heading>
          </FormLabel>
          <Text fontSize="sm" color="blue.400" mb={4}>
            Include details about quantity, if they're paid, rollover policy, etc.
          </Text>
          <Controller
            name="floating-holiday-params"
            control={control}
            render={({ field }) => (
              <TextInput {...field} value={field.value || ""} placeholder="Describe your floating holiday policy..." />
            )}
          />
        </FormControl>
      )}

      {/* Bereavement */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be giving paid time off for bereavement?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-bereavement"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-bereavement") && (
        <FormControl>
          <FormLabel fontWeight="bold">
            <Heading size="md" as="span">
              What is your bereavement policy?
            </Heading>
          </FormLabel>
          <Text fontSize="sm" color="blue.400" mb={4}>
            Include details about who is covered, time allowed, and pay policy.
          </Text>
          <Controller
            name="bereavement-policy"
            control={control}
            render={({ field }) => (
              <TextInput {...field} value={field.value || ""} placeholder="Describe your bereavement policy..." />
            )}
          />
        </FormControl>
      )}

      {/* Parental Leave */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be offering parental leave past FMLA-eligible employees?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-parental-leave"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-parental-leave") && (
        <FormControl>
          <FormLabel fontWeight="bold">
            <Heading size="md" as="span">
              What is your parental leave policy?
            </Heading>
          </FormLabel>
          <Text fontSize="sm" color="blue.400" mb={4}>
            Include details about time allocation, pay, and PTO usage.
          </Text>
          <Controller
            name="parental-leave-policy"
            control={control}
            render={({ field }) => (
              <TextInput {...field} value={field.value || ""} placeholder="Describe your parental leave policy..." />
            )}
          />
        </FormControl>
      )}

      {/* 401K */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be offering a 401K?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-401k"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-401k") && (
        <FormControl>
          <FormLabel fontWeight="bold">
            <Heading size="md" as="span">
              What is your 401K plan?
            </Heading>
          </FormLabel>
          <Text fontSize="sm" color="blue.400" mb={4}>
            Include details about terms, matching, and eligibility.
          </Text>
          <Controller
            name="401k-policy"
            control={control}
            render={({ field }) => (
              <TextInput {...field} value={field.value || ""} placeholder="Describe your 401K plan..." />
            )}
          />
        </FormControl>
      )}

      {/* Life Insurance */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be offering a life insurance plan?
          </Heading>
        </FormLabel>
        <Controller
          name="offers-life-insurance"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {watch("offers-life-insurance") && (
        <FormControl>
          <FormLabel fontWeight="bold">
            <Heading size="md" as="span">
              What are the life insurance plan details?
            </Heading>
          </FormLabel>
          <Text fontSize="sm" color="blue.400" mb={4}>
            Include carrier information and contact details.
          </Text>
          <Controller
            name="life-insurance-details"
            control={control}
            render={({ field }) => (
              <TextInput {...field} value={field.value || ""} placeholder="Provide life insurance plan details..." />
            )}
          />
        </FormControl>
      )}
    </>
  );
};
