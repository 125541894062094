import { useToast, UseToastOptions } from "@chakra-ui/react";

interface ErrorToastOptions extends Omit<UseToastOptions, "status"> {
  title?: string;
  description?: string;
}

export const useErrorToast = () => {
  const toast = useToast();

  const showError = (error: unknown, options: ErrorToastOptions = {}) => {
    const defaultOptions: UseToastOptions = {
      status: "error",
      position: "top",
      duration: 5000,
      isClosable: true
    };

    let description = options.description;
    if (!description) {
      if (error instanceof Error) {
        description = error.message;
      } else if (typeof error === "string") {
        description = error;
      } else {
        description = "An unexpected error occurred. Please try again.";
      }
    }

    toast({
      ...defaultOptions,
      ...options,
      title: options.title || "Error",
      description
    });

    // Also log the error to console for debugging
    console.error("Error occurred:", error);
  };

  return { showError };
};
