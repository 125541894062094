import { useState } from "react";

import { VStack, HStack, Text, Icon } from "@chakra-ui/react";
import { Question, QuestionType } from "@shared/models";
import { FiEdit, FiCheck } from "react-icons/fi";

import { AutoResizingTextarea } from "../../../components/AutoresizingTextArea";
import { BasicInfoFormData } from "../../../components/DynamicQuestion/CustomQuestions/BasicInfo";
import { Session } from "../../../store";
import { formatAnswer, isBasicInfoAnswer, BASIC_INFO_DISPLAY_NAMES } from "../../../utils/answerFormatters";


interface QuestionBoxProps {
  question: Question;
  session: Session;
  onEdit: (questionToUpdate: { questionId: string; reportAnswer: string }) => void;
}

const renderAnswer = (question: Question, answer: string | object | null) => {
  if (question.type === QuestionType.BasicInfo && isBasicInfoAnswer(answer)) {
    return (
      <VStack align="start" spacing={1} pl={4} as="span" w="full">
        {Object.entries(BASIC_INFO_DISPLAY_NAMES).map(([key, displayName]) => (
          <Text fontSize={["sm", "md"]} as="span" w="full" key={key}>
            <HStack w="full" as="span" spacing={4}>
              <Text as="span" w="full" fontWeight="bold">
                {`${displayName}: `}
              </Text>
              <Text as="span" w="full">
                {answer[key as keyof BasicInfoFormData] || "-"}
              </Text>
            </HStack>
          </Text>
        ))}
      </VStack>
    );
  }

  return formatAnswer(answer);
};

export const QuestionBox = ({ question, session, onEdit }: QuestionBoxProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState("");

  const handleEdit = () => {
    if (isEditing) {
      const newReportAnswer = formatAnswer(editValue);

      onEdit({
        questionId: question.id,
        reportAnswer: newReportAnswer as string
      });
      setIsEditing(false);
    } else {
      const currentAnswer = renderAnswer(question, editValue || session[question.id]?.reportAnswer);
      setEditValue(typeof currentAnswer === "string" ? currentAnswer : "");
      setIsEditing(true);
    }
  };

  return (
    <VStack
      key={question.id}
      mt={[0, 2]}
      pl={[0, 4]}
      w="full"
      alignItems="flex-start"
      bg="#FBFBFB"
      p={[2, 4]}
      borderRadius="xl"
      border={isEditing ? "1px solid var(--chakra-colors-brand-500)" : "1px solid #E0E0E0"}
      className="question-box"
    >
      <HStack w="full" justifyContent="space-between">
        <Text fontWeight="bold" fontSize={["md", "lg"]}>
          {question.reportWording}
        </Text>

        {question.type !== QuestionType.BasicInfo && (
          <Icon
            as={isEditing ? FiCheck : FiEdit}
            cursor="pointer"
            fontSize={["md", "lg"]}
            color={isEditing ? "brand.500" : "black.500"}
            _hover={{ color: "brand.500" }}
            onClick={handleEdit}
            className="hide-print"
          />
        )}
      </HStack>

      {isEditing ? (
        <AutoResizingTextarea
          value={editValue}
          onSave={handleEdit}
          onChange={(e) => setEditValue(e.target.value)}
        />
      ) : (
        <Text fontSize={["sm", "md"]} w="full" whiteSpace="pre-wrap" py={[0, 2]} lineHeight="shorter">
          {renderAnswer(question, editValue || session[question.id]?.reportAnswer)}
        </Text>
      )}
    </VStack>
  );
};
