import { useState, useEffect } from "react";

import { BlockNoteView } from "@blocknote/mantine";
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  CreateLinkButton,
  FormattingToolbar,
  FormattingToolbarController,
  NestBlockButton,
  UnnestBlockButton,
  useCreateBlockNote
} from "@blocknote/react";
import {
  VStack,
  Heading,
  Button,
  Box,
  Text,
  Divider,
  ButtonGroup,
  IconButton,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";

interface HandbookReviewProps {
  markdownContent: string;
  onStartFresh: () => void;
}

export const HandbookReview = ({ markdownContent, onStartFresh }: HandbookReviewProps) => {
  const editor = useCreateBlockNote({ trailingBlock: false });
  const [, setIsDirty] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Load the markdown content into the editor
  useEffect(() => {
    const loadHandbook = async () => {
      const markdownBlocks = await editor.tryParseMarkdownToBlocks(markdownContent);
      editor.replaceBlocks(editor.document, markdownBlocks);
      setIsDirty(false);
    };

    loadHandbook();
  }, [editor, markdownContent]);

  const handleCopyToClipboard = async () => {
    try {
      const htmlContent = await editor.blocksToFullHTML(editor.document);
      const blob = new Blob([htmlContent], { type: "text/html" });
      const item = new ClipboardItem({ "text/html": blob });
      await navigator.clipboard.write([item]);
      toast({
        title: "Copied to clipboard",
        description: "Your handbook has been copied to the clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to copy content to clipboard.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
  };

  const handleStartOver = () => {
    onStartFresh();
    onClose();
  };

  return (
    <VStack borderWidth={1} borderRadius="lg" p={4} spacing={4} width="100%">
      <Box px="54px" width="100%">
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="100%" mb={4}>
          <Heading size="lg" my={4} textAlign="center">
            Generated Employee Handbook
          </Heading>
          <ButtonGroup position="absolute" right={0}>
            <Button onClick={onOpen}>Start Over</Button>
            <IconButton onClick={handleCopyToClipboard} aria-label="copy to clipboard">
              <Icon as={MdContentCopy} />
            </IconButton>
          </ButtonGroup>
        </Box>
        <Text fontStyle="italic">
          You can edit your handbook below. Make sure to copy your changes before starting fresh!
        </Text>
      </Box>
      <Divider />
      <BlockNoteView
        editor={editor}
        theme="light"
        sideMenu={false}
        linkToolbar={false}
        filePanel={false}
        slashMenu={false}
        tableHandles={false}
        formattingToolbar={false}
        onChange={() => setIsDirty(true)}
      >
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              <BlockTypeSelect key="blockTypeSelect" />
              <BasicTextStyleButton basicTextStyle="bold" key="boldStyleButton" />
              <BasicTextStyleButton basicTextStyle="italic" key="italicStyleButton" />
              <NestBlockButton key={"nestBlockButton"} />
              <UnnestBlockButton key={"unnestBlockButton"} />
              <CreateLinkButton key={"createLinkButton"} />
            </FormattingToolbar>
          )}
        />
      </BlockNoteView>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to start over?</ModalHeader>
          <ModalBody>
            <Text mb={4}>
              Starting over will delete your current handbook. Make sure you have copied your content to a document for
              future reference.
            </Text>
            <Text fontWeight="bold" color="red.500">
              This action cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={3}>
              <Button onClick={onClose}>Cancel</Button>
              <Button colorScheme="red" onClick={handleStartOver}>
                Yes, Start Over
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
