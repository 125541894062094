import {
  JobDescriptionInfo,
  CreateJobDescriptionRequest,
  SaveGeneratedJobDescriptionRequest,
  GeneratedJobDescriptionWithInfo
} from "@shared/job-description-models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { jobDescriptionService } from "../services/job-description-service";
import { useAppStore } from "../store";
import { useErrorToast } from "./useErrorToast";

export const useJobDescription = (jobDescriptionId?: number) => {
  const queryClient = useQueryClient();
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const { showError } = useErrorToast();

  const { data: completedJobDescriptions, isLoading: isLoadingCompletedJobDescriptions } = useQuery<
    GeneratedJobDescriptionWithInfo[]
  >({
    queryKey: ["allJobDescriptions"],
    queryFn: () => jobDescriptionService.getAllJobDescriptions(),
    enabled: !!userInfo?.id
  });

  const {
    data: previousJobDescription,
    isLoading: isLoadingPreviousJobDescription,
    error: previousJobDescriptionError
  } = useQuery<JobDescriptionInfo | null>({
    queryKey: ["previousJobDescription"],
    queryFn: () => jobDescriptionService.getPreviousJobDescription(),
    enabled: !!userInfo?.id && !jobDescriptionId
  });

  const { data: generatedJobDescription, isLoading: isLoadingGeneratedJobDescription } = useQuery({
    queryKey: ["generatedJobDescription", jobDescriptionId],
    queryFn: () => jobDescriptionService.getGeneratedJobDescription(jobDescriptionId!),
    enabled: !!jobDescriptionId && !!userInfo?.id
  });

  const { mutateAsync: createJobDescription, isPending: isCreatingJobDescription } = useMutation({
    mutationFn: (data: CreateJobDescriptionRequest) => jobDescriptionService.createJobDescription(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["previousJobDescription"] });
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Create Job Description",
        description: "There was an error creating your job description. Please try again."
      });
    }
  });

  const { mutateAsync: saveGeneratedJobDescription, isPending: isSavingGeneratedJobDescription } = useMutation({
    mutationFn: (data: SaveGeneratedJobDescriptionRequest) => jobDescriptionService.saveGeneratedJobDescription(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["previousJobDescription"] });
      queryClient.invalidateQueries({ queryKey: ["generatedJobDescription", jobDescriptionId] });
      queryClient.invalidateQueries({ queryKey: ["completedJobDescriptions"] });
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Save Job Description",
        description: "There was an error saving your job description. Please try again."
      });
    }
  });

  return {
    previousJobDescription,
    isLoadingPreviousJobDescription,
    previousJobDescriptionError,
    generatedJobDescription,
    isLoadingGeneratedJobDescription,
    createJobDescription,
    isCreatingJobDescription,
    saveGeneratedJobDescription,
    isSavingGeneratedJobDescription,
    completedJobDescriptions,
    isLoadingCompletedJobDescriptions
  };
};
