import { FormControl, FormLabel, Heading, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

import { HandbookFormData } from "../types";
import { INCIDENT_REPORTING_OPTIONS } from "./constants";

interface SectionProps {
  control: Control<HandbookFormData>;
}

export const PropertyAndIncidentSection = ({ control }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Company Property, Safety and Incident Reporting</Heading>

      {/* Company Vehicle Use */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will driving a work vehicle be involved in your business? (food truck, catering van, company car, etc.)
          </Heading>
        </FormLabel>
        <Controller
          name="uses-company-vehicles"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {/* Personal Vehicle Use */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will driving their own car be required for work? (delivery driver, etc.)
          </Heading>
        </FormLabel>
        <Controller
          name="requires-personal-vehicles"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {/* Incident Reporting */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How will you be filing incident reports? (injuries of staff or guests, food-borne illness claims,
            theft/robbery, etc.)
          </Heading>
        </FormLabel>
        <Controller
          name="incident-reporting"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value} colorScheme="brand">
              <Stack direction="column" spacing={3}>
                {INCIDENT_REPORTING_OPTIONS.map((option) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {/* Alcohol Service */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you serve alcohol?
          </Heading>
        </FormLabel>
        <Controller
          name="serves-alcohol"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
    </>
  );
};
