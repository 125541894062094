import { Fragment } from "react";

import { VStack, Heading, Divider } from "@chakra-ui/react";

import { QuestionBox } from "./QuestionBox";
import { Session } from "../../../store";

import { FilteredSection } from ".";

interface SectionContentProps {
  sections: FilteredSection[];
  session: Session;
  onEdit: (questionToUpdate: { questionId: string; reportAnswer: string }) => void;
}

export const SectionContent = ({ sections, session, onEdit }: SectionContentProps) => (
  <VStack
    overflowY="auto"
    w="full"
    h={["calc(100vh - 300px)", "calc(100vh - 350px)"]}
    alignItems="flex-start"
    justifyContent="flex-start"
    className="print-content"
    pr={[0, 4]}
  >
    {sections.map((section) => (
      <VStack key={section.id} w="full" alignItems="flex-start" mb={[2, 8]}>
        <Heading size={["md", "lg"]}>{section.name}</Heading>
        <Divider w="full" />
        {section.pages.map((page) => (
          <Fragment key={page.id}>
            {page.questions.map((question) => (
              <QuestionBox key={question.id} question={question} session={session} onEdit={onEdit} />
            ))}
          </Fragment>
        ))}
      </VStack>
    ))}
  </VStack>
);
