import { useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { useErrorToast } from "./useErrorToast";
import { processPdf } from "../services/api-service";

export const usePdfUpload = () => {
  const [extractedItems, setExtractedItems] = useState<string[]>([]);
  const { showError } = useErrorToast();

  const processPdfMutation = useMutation({
    mutationFn: processPdf,
    onSuccess: (items: string[]) => {
      setExtractedItems((prev) => {
        const existingItems = new Set(prev);
        const newItems = items.filter((item) => !existingItems.has(item));
        return [...prev, ...newItems];
      });
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Process PDF",
        description:
          "There was an error processing your PDF file. Please ensure the file is not corrupted and try again."
      });
    }
  });

  const uploadAndProcessPdf = async (file: File): Promise<void> => {
    await processPdfMutation.mutateAsync(file);
  };

  const clearExtractedItems = () => {
    setExtractedItems([]);
  };

  return {
    uploadAndProcessPdf,
    extractedItems,
    clearExtractedItems,
    isUploading: processPdfMutation.isPending,
    isError: processPdfMutation.isError,
    error: processPdfMutation.error
  };
};
