import { useEffect, useState } from "react";

import { Center, Spinner, Heading, Text, Button, VStack, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";

import { JobDescriptionBuilderForm } from "./JobDescriptionBuilderForm";
import { JobDescriptionReview } from "./JobDescriptionReview";
import { MobileWarningModal } from "../../components/MobileWarningModal";
import { useJobDescription } from "../../hooks/useJobDescription";
import { useRestaurantInfo } from "../../hooks/useRestaurantInfo";

const JobDescriptionBuilderPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (isMobile) {
      onOpen();
    }
  }, [isMobile, onOpen]);

  const { restaurantInfo, isLoadingRestaurantInfo } = useRestaurantInfo();
  const {
    previousJobDescription,
    isLoadingPreviousJobDescription,
    generatedJobDescription,
    isLoadingGeneratedJobDescription
  } = useJobDescription(id ? parseInt(id) : undefined);

  const handleJobDescriptionGenerated = (jobDescriptionId: number) => {
    navigate(`/job-builder/${jobDescriptionId}`);
  };

  const handleStartFresh = () => {
    navigate("/job-builder");
  };

  // Show loading state while fetching initial data or during form processing
  if (
    isLoadingRestaurantInfo ||
    isLoadingPreviousJobDescription ||
    (id && isLoadingGeneratedJobDescription) ||
    isFormProcessing
  ) {
    return (
      <Center flexDirection="column" gap={6} height="90vh">
        <Spinner size="xl" speed="0.65s" thickness="4px" color="brand.500" />
        <Heading size="sm">{isFormProcessing ? "Generating your job description..." : "Loading..."}</Heading>
      </Center>
    );
  }

  // If we're trying to view a specific job description
  if (id && !isLoadingGeneratedJobDescription) {
    // Check if the job description exists and belongs to the user
    if (!generatedJobDescription) {
      return (
        <VStack spacing={4} pt={8}>
          <Text>Job description not found or you don't have permission to view it.</Text>
          <Button onClick={() => navigate("/job-builder")}>Create New Job Description</Button>
        </VStack>
      );
    }

    return <JobDescriptionReview jobDescriptionId={parseInt(id)} onStartFresh={handleStartFresh} />;
  }

  // Show the form with pre-populated data
  return (
    <>
      <MobileWarningModal isOpen={isOpen} onClose={onClose} />
      <JobDescriptionBuilderForm
        restaurantInfo={restaurantInfo || null}
        previousJobDescription={previousJobDescription || null}
        onJobDescriptionGenerated={handleJobDescriptionGenerated}
        onLoadingStateChange={setIsFormProcessing}
      />
    </>
  );
};

export default JobDescriptionBuilderPage;
