import { Text } from "@chakra-ui/react";
import { Page } from "@shared/models";

import TransparentCheckbox, { CustomCheckboxIcon } from "./TrasparentCheckbox";

interface PageItemProps {
  page: Page;
  isChecked: boolean;
  isIndeterminate: boolean;
  onClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
}

const PageItem = ({ page, isChecked, isIndeterminate, onClick, isActive, isDisabled }: PageItemProps) => (
  <TransparentCheckbox
    isChecked={isChecked}
    isIndeterminate={isIndeterminate}
    onChange={isDisabled ? undefined : onClick}
    ml={10}
    size="lg"
    cursor={isDisabled ? "not-allowed" : "pointer"}
    icon={<CustomCheckboxIcon isIndeterminate={isIndeterminate} isChecked={isChecked} />}
  >
    <Text
      fontSize={["sm", "xs", "xs", "xs", "sm"]}
      fontWeight={isActive ? "bold" : "normal"}
      color={isDisabled ? "gray.400" : isActive ? "brand.primary" : "inherit"}
    >
      {page.name}
    </Text>
  </TransparentCheckbox>
);

export default PageItem;
