import React from "react";

import { VStack, Text, Button, HStack } from "@chakra-ui/react";
import { MdPersonSearch } from "react-icons/md";

interface CompletedArtifactCardProps {
  title: string;
  createdAt: string;
  buttonText: string;
  handleClick: () => void;
}

export const CompletedArtifactCard: React.FC<CompletedArtifactCardProps> = ({
  title,
  createdAt,
  buttonText,
  handleClick
}) => {
  return (
    <VStack
      w="full"
      spacing={[2, 4]}
      p={[4, 8]}
      bg="white"
      borderRadius="xl"
      cursor="pointer"
      _hover={{ transform: "translateY(-2px)", transition: "transform 0.2s" }}
      align="flex-start"
      h="full"
      border="solid"
      borderWidth={2}
      borderColor="blackAlpha.200"
      onClick={handleClick}
    >
      <HStack w="full" gap={2} alignItems="start">
        <MdPersonSearch size={25} />
        <Text fontSize="lg" fontWeight="semibold">
          {title}
        </Text>
      </HStack>
      <Text fontSize="sm" color="gray.500">
        Created {new Date(createdAt).toLocaleDateString()}
      </Text>
      <Button
        alignSelf="flex-end"
        justifySelf="flex-end"
        color="black"
        variant="outline"
        borderColor="brand.500"
        size="sm"
        onClick={handleClick}
        mt="2"
      >
        {buttonText}
      </Button>
    </VStack>
  );
};
