import { QuestionDependencyType, QuestionType, SOPGuide } from "@shared/models";

const FoodItems = [
  "Soup",
  "Salad",
  "Chili",
  "Rice",
  "Bread",
  "Hamburger",
  "Pizza",
  "Nachos",
  "Eggs",
  "Pasta",
  "Fruits",
  "Veggies",
  "Fish",
  "Roasts",
  "Stuffed Fish",
  "Ratites"
];

const trainingMethods = ["Handbook", "Training Manual", "Video's", "Mentors & Trainers", "Checklists", "Team Meetings"];
const trainingPic = ["Owner", "Manager", "Chef", "Person in Charge (PIC)"];

const EquipmentTypes = ["Cooking", "Hot Holding", "Cooling", "Cold Holding"];

const CoolingMethods = [
  "Placing food in shallow pans",
  "Covering food loosely or leaving it uncovered to help cool faster when placed in cold holding equipment",
  "Separating food into smaller or thinner portions",
  "Using rapid cooling equipment (ice paddle, blast chiller)",
  "Ice bath with regular stirring",
  "Adding ice as an ingredient",
  "Using containers that facilitate heat transfer"
];

// ---------------------------------------------------------------------------------------------------------------------
// See the workflow.md file for more information on how this is used
// ---------------------------------------------------------------------------------------------------------------------

export const workflow: SOPGuide = {
  sections: [
    {
      id: "restaurant-information",
      urlSlug: "restaurant-information",
      name: "Restaurant Information",
      pages: [
        {
          id: "restaurant-information-basic-info",
          urlSlug: "basic-info",
          name: "Basic Info",
          header: "",
          questions: [
            {
              id: "restaurant-information-basic-info-details",
              reportWording: "Basic Information",
              type: QuestionType.BasicInfo,
              prompt: "Basic Information",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        // {
        //   id: "restaurant-information-menu",
        //   urlSlug: "menu",
        //   name: "Menu",
        //   header: "",
        //   questions: [
        //     {
        //       id: "restaurant-information-menu-menu",
        //       reportWording:
        //         "If you have your menu as a document (pdf, word, etc.) or on a website, we may be able to help you fill out some of the sections. Please upload your menu, or provide a link, or skip if you don't have a way to share it at the moment.",
        //       type: QuestionType.Custom,
        //       prompt:
        //         "If you have your menu as a document (pdf, word, etc.) or on a website, we may be able to help you fill out some of the sections. Please upload your menu, or provide a link, or skip if you don't have a way to share it at the moment.",
        //       options: [],
        //       required: true,
        //       footnote: "",
        //       excludeFromReport: true
        //     }
        //   ]
        // },
        {
          id: "restaurant-information-food-groupings",
          urlSlug: "food-groupings",
          name: "Food Groupings",
          header: "",
          questions: [
            {
              id: "restaurant-information-food-groupings-food",
              reportWording:
                "What food groupings will be present at your restaurant? (These answers will be used to speed up future questions)",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What food groupings will be present at your restaurant? (These answers will be used to speed up future questions)",
              options: [
                "Meat",
                "Eggs",
                "Dairy",
                "Fish",
                "Produce",
                "Baked Goods",
                "Dry Goods",
                "Non Alcoholic Beverages",
                "Liquor",
                "Beer",
                "Wine"
              ],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "restaurant-information-food-items",
          urlSlug: "food-items",
          name: "Food Items",
          header: "",
          questions: [
            {
              id: "restaurant-information-food-items-food",
              reportWording: "What food items will be used at your restaurant?",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What menu items will be served at your restaurant? (These answers will be used to speed up future questions)",
              options: FoodItems,
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "restaurant-information-equipment-list",
          urlSlug: "equipment-list",
          name: "Equipment",
          header: "",
          questions: [
            {
              id: "restaurant-information-equipment-list-ice",
              reportWording: "What type and brand of ice machine will be used?",
              type: QuestionType.Textarea,
              prompt: "What type and brand of ice machine will be used?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            },
            {
              id: "restaurant-information-equipment-list-roasting",
              reportWording: "Below is if you will be roasting whole meats:",
              type: QuestionType.YesNo,
              prompt: "Are you roasting whole meats?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            },
            {
              id: "restaurant-information-equipment-list-roasting-equipment",
              reportWording: "What equipment are you roasting whole meats in?",
              type: QuestionType.Textarea,
              prompt: "What equipment are you roasting whole meats in?",
              options: [],
              required: false,
              footnote: "",
              excludeFromReport: true,
              dependencies: [
                { questionId: "restaurant-information-equipment-list-roasting", type: QuestionDependencyType.Silent }
              ]
            },
            {
              id: "restaurant-information-equipment-list-thermometers",
              reportWording: "Which type of thermometer(s) will be used in your facility?",
              type: QuestionType.MultiselectWithOther,
              prompt: "Which type of thermometer(s) will be used in your facility?",
              options: ["Probe Thermometer", "Surface Thermometer", "Immersion Thermometer"],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "restaurant-information-equipment-upload",
          urlSlug: "equipment-upload",
          name: "Equipment List Upload",
          header: "",
          questions: [
            {
              id: "restaurant-information-equipment-list-equipment",
              reportWording:
                "Upload an equipment list to generate a full heating and cooling food equipment inventory.",
              type: QuestionType.FileUpload,
              prompt: "Upload an equipment list to generate a full heating and cooling food equipment inventory.",
              options: [],
              required: false,
              footnote: "Don't have an equipment list? You can manually add items by entering them below.",
              excludeFromReport: true,
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-list-ice",
                  type: QuestionDependencyType.NotRequired,
                  label: "Equipment"
                },
                {
                  questionId: "restaurant-information-equipment-list-roasting",
                  type: QuestionDependencyType.NotRequired,
                  label: "Equipment"
                },
                {
                  questionId: "restaurant-information-equipment-list-thermometers",
                  type: QuestionDependencyType.NotRequired,
                  label: "Thermometers"
                },
                {
                  questionId: "restaurant-information-equipment-list-roasting-equipment",
                  type: QuestionDependencyType.NotRequired,
                  label: "Ice Machine"
                }
              ]
            }
          ]
        },
        {
          id: "restaurant-information-equipment-review",
          urlSlug: "equipment-review",
          name: "Equipment Review",
          header: "",
          questions: [
            {
              id: "restaurant-information-equipment-review-equipment-review",
              reportWording: "Below is the full equipment list:",
              prompt: "Let's finalize the equipment information.",
              options: EquipmentTypes,
              type: QuestionType.AccordionMultiSelect,
              required: true,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-list-ice",
                  type: QuestionDependencyType.NotRequired,
                  label: "Equipment"
                },
                {
                  questionId: "restaurant-information-equipment-list-roasting",
                  type: QuestionDependencyType.NotRequired,
                  label: "Equipment"
                },
                {
                  questionId: "restaurant-information-equipment-list-equipment",
                  type: QuestionDependencyType.NotRequired,
                  label: "Equipment"
                },
                {
                  questionId: "restaurant-information-equipment-list-thermometers",
                  type: QuestionDependencyType.NotRequired,
                  label: "Thermometers"
                },
                {
                  questionId: "restaurant-information-equipment-list-roasting-equipment",
                  type: QuestionDependencyType.NotRequired,
                  label: "Ice Machine"
                }
              ]
            }
          ]
        },
        {
          id: "restaurant-information-catering",
          urlSlug: "catering",
          name: "Catering",
          header: "",
          questions: [
            {
              id: "restaurant-information-catering-catering",
              reportWording: "Below is if we will be offering catering, off-site or satellite food service:",
              type: QuestionType.YesNo,
              prompt: "Will you be offering catering, off-site or satellite food service?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "restaurant-information-outdoor-dining",
          urlSlug: "outdoor-dining",
          name: "Outdoor Dining",
          header: "",
          questions: [
            {
              id: "restaurant-information-outdoor-outdoor-dining",
              reportWording: "Below is if you will have outdoor/exposed dining:",
              type: QuestionType.YesNo,
              prompt: "Will you have outdoor/exposed dining?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "restaurant-information-outdoor-prep",
          urlSlug: "outdoor-prep",
          name: "Outdoor Prep",
          header: "",
          questions: [
            {
              id: "restaurant-information-outdoor-outdoor-prep",
              reportWording: "Below is if you will have outdoor/exposed food preparation:",
              type: QuestionType.YesNo,
              prompt: "Will you have outdoor/exposed food preparation?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        }
      ]
    },
    {
      id: "pic-training-discipline",
      urlSlug: "pic-training-discipline",
      name: "PIC, Training, & Discipline",
      pages: [
        {
          id: "pic-training-discipline-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "pic-training-discipline-training-pic",
              reportWording:
                "Below will be responsible for training employees on your SOPs, and disciplining them when they are not followed: (Below represents the answer for all sections of the SOP document)",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Who will be responsible for training employees on your SOPs, and disciplining them when they are not followed?",
              options: trainingPic,
              required: true,
              footnote: "This will represent all sections with the same information for the SOP on who is responsible.",
              excludeFromReport: true
            },
            {
              id: "pic-training-discipline-training-training-methods",
              reportWording:
                "Below are the methods for training employees on your completed SOPs: (Below represents the answer for all sections of the SOP document)",
              type: QuestionType.MultiselectWithOther,
              prompt: "Describe the methods for training employees on your completed SOPs",
              options: trainingMethods,
              required: true,
              footnote: "This will represent all sections with the same information for the SOP on training methods.",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "pic-training-discipline-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "pic-training-discipline-corrective-action-corrective-action",
              reportWording: "Below are the corrective action(s) that will be used when SOPs are not followed:",
              type: QuestionType.Textarea,
              prompt: "What is the corrective action that will be used when SOPs are not followed?",
              options: [
                "1. If someone doesn't follow the required procedure, they will get a verbal reminder and written documentation will go into their employee file. Extra training on the approved procedure and why it's important will be provided.\n\n2. If it happens again a written warning to be signed will be administered to be placed into their file. Extra training on the approved procedure and why it's important will be provided.\n\n3. A third infraction will result in written documentation up to and including termination. The responsible party will check that everyone is following the procedure properly, give or assign training, and take action if needed."
              ],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        }
      ]
    },
    {
      id: "handwashing",
      urlSlug: "handwashing",
      name: "Handwashing",
      pages: [
        {
          id: "handwashing-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "handwashing-proper-use-understand",
              reportWording: "We understand the rules for proper handwashing:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper handwashing?",
              contentFile: "/SOPContext/Handwashing.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "handwashing-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "handwashing-training-pic",
              reportWording: "Below is who will be responsible for training employees on handwashing:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on handwashing, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required,
                  label: "Handwashing"
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "handwashing-training-training-methods",
              reportWording: "Below are the methods for training employees on handwashing:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on handwashing",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required,
                  label: "Handwashing"
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "handwashing-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "handwashing-corrective-action-corrective-action",
              reportWording: "Below are the corrective action(s) that will be used when handwashing is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when handwashing is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "personal-hygiene",
      urlSlug: "personal-hygiene",
      name: "Personal Hygiene",
      pages: [
        {
          id: "personal-hygiene-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "personal-hygiene-proper-use-understand",
              reportWording: "We understand the rules for proper personal hygiene:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper personal hygiene?",
              contentFile: "/SOPContext/PersonalHygiene.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "personal-hygiene-training-pic",
              reportWording: "Below is who will be responsible for training employees on personal hygiene:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on personal hygiene, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "personal-hygiene-training-training-methods",
              reportWording: "Below are the methods for training employees on personal hygiene:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on personal hygiene",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "personal-hygiene-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when personal hygiene is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when personal hygiene is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-food-drink",
          urlSlug: "food-drink",
          name: "Food & Drink",
          header: "",
          questions: [
            {
              id: "personal-hygiene-food-drink-eat-locations",
              reportWording: "Below are the location(s) where employees will be permitted to eat and drink:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Where will employees be permitted to eat and drink?",
              options: ["Break Room", "Outdoor Area/Patio", "Back Dock", "Back Office", "Dining Area", "Bar Area"],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "personal-hygiene-food-drink-drinks",
              reportWording: "Below is if we will allow drinks in the food handling area:",
              type: QuestionType.YesNo,
              prompt: "Will you allow drinks in the food handling area?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "personal-hygiene-food-drink-containers",
              reportWording: "Below are the types of containers that will be used (if applicable):",
              type: QuestionType.Textarea,
              prompt: "What types of containers will be used?",
              options: ["Cups with lids and straws."],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [{ questionId: "personal-hygiene-food-drink-drinks", type: QuestionDependencyType.Silent }]
            }
          ]
        },
        {
          id: "personal-hygiene-tobacco",
          urlSlug: "tobacco",
          name: "Tobacco",
          header: "",
          questions: [
            {
              id: "personal-hygiene-tobacco-tobacco",
              reportWording: "Employees will be permitted to use tobacco products in the following location(s):",
              type: QuestionType.MultiselectWithOther,
              prompt: "Where will employees be permitted to use tobacco products?",
              options: ["Outdoors"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "personal-hygiene-hair-restraint",
          urlSlug: "hair-restraint",
          name: "Hair Restraint",
          header: "",
          questions: [
            {
              id: "personal-hygiene-hair-restraint-hair",
              reportWording: "Below are the types of hair restraints that will be used in the facility:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What types of hair restraints will be used in your facility?",
              options: ["Baseball Hat", "Chef's Hat", "Skull Cap", "Bandana", "Hair Net"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "bare-hand-contact",
      urlSlug: "bare-hand-contact",
      name: "Bare Hand Contact",
      pages: [
        {
          id: "bare-hand-contact-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "bare-hand-contact-proper-use-understand",
              reportWording: "We understand the rules for proper bare hand contact:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper bare hand contact?",
              contentFile: "/SOPContext/RTEFoods.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "bare-hand-contact-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "bare-hand-contact-training-pic",
              reportWording: "Below is who will be responsible for training employees on bare hand contact:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on bare hand contact, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "bare-hand-contact-training-training-methods",
              reportWording: "Below are the methods for training employees on bare hand contact:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on bare hand contact",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "bare-hand-contact-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "bare-hand-contact-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when bare hand contact is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when bare hand contact is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "bare-hand-contact-ready-to-eat-utensils",
          urlSlug: "ready-to-eat-utensils",
          name: "Ready-to-eat Utensils",
          header: "",
          questions: [
            {
              id: "bare-hand-contact-ready-to-eat-utensils-utensils",
              reportWording:
                "Below are all the items that apply to how we will serve RTE (Ready-to-Eat) foods with utensils:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Please select all items that apply to how you will serve RTE (Ready-to-Eat) foods with utensils.",
              options: ["Spatula", "Tongs", "Serving Spoons", "Shakers", "Scoop"],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "bare-hand-contact-ready-to-eat-utensils-disposable-gloves",
              reportWording:
                "Below are all the items that apply to how we will serve RTE foods with disposable gloves:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select all items that apply to how you will serve RTE foods with disposable gloves.",
              options: ["Latex", "Nitrile (great for sensitive skin and allergen issues)", "Vinyl"],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "bare-hand-contact-ready-to-eat-utensils-dispensing-equipment",
              reportWording:
                "Below are all the items that apply to how we will serve RTE foods with dispensing equipment:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select all items that apply to how you will serve RTE foods with dispensing equipment.",
              options: ["Pump", "Squeeze bottle", "Speed pours"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "employee-health",
      urlSlug: "employee-health",
      name: "Employee Health",
      pages: [
        {
          id: "employee-health-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "employee-health-proper-use-understand",
              reportWording: "We understand the rules for proper employee health management:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for proper employee health management?",
              contentFile: "/SOPContext/EmployeeHealth.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "employee-health-training-pic",
              reportWording: "Below is who will be responsible for training employees on employee health:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on employee health, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "employee-health-training-training-methods",
              reportWording: "Below are the methods for training employees on employee health:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on employee health",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "employee-health-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when employee health is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when employee health is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-employee-reporting",
          urlSlug: "employee-reporting",
          name: "Employee Reporting",
          header: "",
          questions: [
            {
              id: "employee-health-employee-reporting-awareness",
              reportWording: "Below is how we will make our employees aware of the rules:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please select how will you make your employees aware of the rules.",
              options: ["Posters", "Handbook", "Orientation", "Training"],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "employee-health-employee-reporting-recording",
              reportWording: "Below is how employee health records will be kept:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What employee health records will be kept?",
              options: ["Doctor's notes", "Written employee documentation"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "employee-health-recording-illness",
          urlSlug: "recording-illness",
          name: "Recording Illness",
          header: "",
          questions: [
            {
              id: "employee-health-recording-illness-illness-records",
              reportWording:
                "Will records be kept for food employees who have been restricted or excluded from work due to symptoms or illness within this procedure and where will these records be kept?",
              type: QuestionType.Textarea,
              prompt:
                "Will records be kept for food employees who have been restricted or excluded from work due to symptoms or illness within this procedure and where will these records be kept?",
              options: ["Records will be kept in respective employee files following HIPPA compliance guidelines."],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "food-sources",
      urlSlug: "food-sources",
      name: "Food Sources",
      pages: [
        {
          id: "food-sources-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "food-sources-proper-use-understand",
              reportWording: "We understand the rules regarding food from approved sources:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules regarding food from approved sources?",
              contentFile: "/SOPContext/ApprovedFoodSources.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-sources-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "food-sources-training-pic",
              reportWording: "Below is who will be responsible for training employees on food sources:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on food sources, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "food-sources-training-training-methods",
              reportWording: "Below are the methods for training employees on bare hand contact:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on food sources",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-sources-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "food-sources-corrective-action-corrective-action",
              reportWording: "Below are the corrective action(s) that will be used when food sources are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when food sources are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-sources-food-suppliers",
          urlSlug: "food-suppliers",
          name: "Food Suppliers",
          header: "",
          questions: [
            {
              id: "food-sources-food-suppliers-suppliers",
              reportWording:
                "Below are the vendor(s) who will be used to receive our product. These very well will change over time, but we willmake sure they are approved by the health department when using a new supplier.",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Please select the vendor(s) you will be using to receive your product. These very well will change over time, but make sure they are approved by the health department when using a new supplier. We've started with a few common suppliers, please add any additional source.",
              options: [
                "US foods",
                "Sysco",
                "Gordon's Food Service",
                "Gelardi Produce",
                "Superior Foods",
                "Great Lakes Wine and Spirits",
                "National Wine and Spirits",
                "Henry Fox",
                "MLCC"
              ],
              required: true,
              footnote: "",
              excludeFromReport: true
            }
          ]
        },
        {
          id: "food-sources-food-supplier-items",
          urlSlug: "food-supplier-items",
          name: "Food Supplier Items",
          header: "",
          questions: [
            {
              id: "food-sources-food-supplier-items-supplier-items",
              reportWording: "For each supplier, we selected which food groups they will be providing:",
              type: QuestionType.TableSelect,
              prompt: "For each supplier, please select which food groups you will be receiving.",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "food-sources-food-suppliers-suppliers",
                  type: QuestionDependencyType.Required,
                  label: "Suppliers"
                },
                // for table select, last dependency is is X axis
                {
                  questionId: "restaurant-information-food-groupings-food",
                  type: QuestionDependencyType.Required,
                  label: "Food"
                }
              ]
            }
          ]
        },
        {
          id: "food-sources-food-receiving",
          urlSlug: "food-receiving",
          name: "Food Receiving",
          header: "",
          questions: [
            {
              id: "food-sources-food-receiving-receiving",
              reportWording:
                "Below is the procedure for receiving and inspecting food (e.g. condition of packaging, temperature, etc.) and criteria for rejecting food items:",
              type: QuestionType.Textarea,
              prompt:
                "Describe your procedure for receiving and inspecting food (e.g. condition of packaging, temperature, etc.) and criteria for rejecting food items.",
              options: [
                "All deliveries will be checked in by a PIC, or someone trained by a PIC. Conditions of packaging including seepage, dented cans and frostbiten/time-temperature abuse items will be sent back. Produce will be examined for freshness and temperature. Fish gills, eyes and flesh will be tested for freshness. Temperature checks on items received when applicable."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-sources-unacceptable-food",
          urlSlug: "unacceptable-food",
          name: "Unacceptable Food",
          header: "",
          questions: [
            {
              id: "food-sources-unacceptable-food-unacceptable",
              reportWording:
                "Below is what will be done with food that is not from an approved source or food that is delivered from an approved source but found to be in unacceptable condition (e.g. damaged product, temperature abused, etc.):",
              type: QuestionType.Textarea,
              prompt:
                "Describe what will be done with food that is not from an approved source or food that is delivered from an approved source but found to be in unacceptable condition (e.g. damaged product, temperature abused, etc.)",
              options: ["Food will be sent back on the delivery truck."],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "warewashing",
      urlSlug: "warewashing",
      name: "Warewashing",
      pages: [
        {
          id: "warewashing-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "warewashing-proper-use-understand",
              reportWording: "We understand the rules for warewashing/dishwashing:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for warewashing/dishwashing?",
              contentFile: "/SOPContext/Warewashing.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "warewashing-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "warewashing-training-pic",
              reportWording: "Below is who will be responsible for training employees on warewashing:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on warewashing, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "warewashing-training-training-methods",
              reportWording: "Below are the methods for training employees on warewashing:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on warewashing",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "warewashing-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "warewashing-corrective-action-corrective-action",
              reportWording: "Below are the corrective action(s) that will be used when warewashing is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when warewashing is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "warewashing-sink",
          urlSlug: "sink",
          name: "Sink",
          header: "",
          questions: [
            {
              id: "warewashing-sink-sink",
              reportWording:
                "Below is if we will be using a three-compartment sink for cleaning and sanitizing food contact surfaces:",
              type: QuestionType.YesNo,
              prompt: "Will you be using a three-compartment sink for cleaning and sanitizing food contact surfaces?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "warewashing-sink-sink-utensils",
              reportWording: "Below are the utensils that will be cleaned and sanitized in the three-compartment sink:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Which utensils will be cleaned and sanitized in the three-compartment sink?",
              options: [
                "Tableware",
                "Cutlery",
                "Glassware",
                "Cookware",
                "Cooking Utensils",
                "Small Appliances",
                "Bakeware",
                "Food Storage Containers",
                "Kitchen Equipment",
                "Cutting Boards",
                "Serving Utensils",
                "Trays",
                "Barware"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-sink-sink",
                  type: QuestionDependencyType.Silent
                }
              ]
            },
            {
              id: "warewashing-sink-sink-methods",
              reportWording:
                "Below is the frequency and type of chemical sanitizer used. Also, how and when the concentration be tested:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What frequency and type of chemical sanitizer is used? How and when will the concentration be tested?",
              options: [
                "Chlorine: According to manufacturer guidelines and Safety Data Sheets",
                "Quaternary Ammonium (Quat): According to manufacturer guidelines and Safety Data Sheets",
                "Acids: According to manufacturer guidelines and Safety Data Sheets"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-sink-sink",
                  type: QuestionDependencyType.Silent
                }
              ]
            }
          ]
        },
        {
          id: "warewashing-machine",
          urlSlug: "machine",
          name: "Machine",
          header: "",
          questions: [
            {
              id: "warewashing-machine-machine-chemical-sanitizing",
              reportWording: "Below is if we will be using a chemical sanitizing dishmachine:",
              type: QuestionType.YesNo,
              prompt: "Will you be using a chemical sanitizing dishmachine?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "warewashing-machine-machine-methods",
              reportWording:
                "Below is the frequency and type of chemical sanitizer to be used, as well as how and when the concentration will be tested:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What frequency and type of chemical sanitizer is used? How and when will the concentration be tested?",
              options: [
                "Chlorine: According to manufacturer guidelines and Safety Data Sheets",
                "Quaternary Ammonium (Quat): According to manufacturer guidelines and Safety Data Sheets",
                "Acids: According to manufacturer guidelines and Safety Data Sheets"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-machine-machine-chemical-sanitizing",
                  type: QuestionDependencyType.Silent
                }
              ]
            },
            {
              id: "warewashing-machine-machine-high-temperature-sanitizing",
              reportWording: "Below is if we will be using a High temperature sanitizing dishmachine:",
              type: QuestionType.YesNo,
              prompt: "Will you be using a High temperature sanitizing dishmachine?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "warewashing-machine-machine-utensils",
              reportWording: "Below are the utensils we will be cleaned and sanitized in the dish machine:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Which utensils will be cleaned and sanitized in the dish machine?",
              options: [
                "Tableware",
                "Cutlery",
                "Glassware",
                "Cookware",
                "Cooking Utensils",
                "Small Appliances",
                "Bakeware",
                "Food Storage Containers",
                "Kitchen Equipment",
                "Cutting Boards",
                "Serving Utensils",
                "Trays",
                "Barware"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "warewashing-in-place",
          urlSlug: "in-place",
          name: "In Place",
          header: "",
          questions: [
            {
              id: "warewashing-in-place-place",
              reportWording: "Below is if we will be using in-place cleaning of large equipment/utensils:",
              type: QuestionType.YesNo,
              prompt: "Will you be using in-place cleaning of large equipment/utensils?",
              options: [],
              required: true,
              footnote:
                "In-place cleaning is the warewashing of food contact surfaces of large equipment or utensils that cannot be placed into a three-compartment sink or dishmachine (e.g. meat slicers, food preparation tables, floor mixers)",
              excludeFromReport: false
            },
            {
              id: "warewashing-in-place-place-utensils",
              reportWording: "Below are the utensils that will be cleaned and sanitized by in-place cleaning:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Which utensils will be cleaned and sanitized by in-place cleaning?",
              options: [
                "Tableware",
                "Cutlery",
                "Glassware",
                "Cookware",
                "Cooking Utensils",
                "Small Appliances",
                "Bakeware",
                "Food Storage Containers",
                "Kitchen Equipment",
                "Cutting Boards",
                "Serving Utensils",
                "Trays",
                "Barware"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-in-place-place",
                  type: QuestionDependencyType.Silent
                }
              ]
            },
            {
              id: "warewashing-in-place-place-methods",
              reportWording:
                "Below is the frequency and type of chemical sanitizer used. Also, how and when the concentration will be tested:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What frequency and type of chemical sanitizer is used? How and when will the concentration be tested?",
              options: [
                "Chlorine: According to manufacturer guidelines and Safety Data Sheets",
                "Quaternary Ammonium (Quat): According to manufacturer guidelines and Safety Data Sheets",
                "Acids: According to manufacturer guidelines and Safety Data Sheets"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-in-place-place",
                  type: QuestionDependencyType.Silent
                }
              ]
            }
          ]
        },
        {
          id: "warewashing-cip",
          urlSlug: "cip",
          name: "CIP",
          header: "",
          questions: [
            {
              id: "warewashing-cip-cip",
              reportWording:
                "Below is if we will be using Clean in Place (CIP) for cleaning and sanitizing (warewashing) of food contact surfaces:",
              type: QuestionType.YesNo,
              prompt:
                "Will you be using Clean in Place (CIP) for cleaning and sanitizing (warewashing) of food contact surfaces?",
              options: [],
              required: true,
              footnote:
                "Clean in place (CIP) is a process where food contact surfaces of equipment are cleaned and sanitized by the circulation or flow of a detergent solution, water rinse, and sanitizing solution onto or over equipment surfaces by mechanical means through a piping system (e.g. brewery equipment, frozen dessert machines).",
              excludeFromReport: false
            },
            {
              id: "warewashing-cip-cip-utensils",
              reportWording:
                "Below are the utensils that will be cleaned and sanitized with a clean in place (CIP) process:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Which utensils will be cleaned and sanitized with a clean in place (CIP) process?",
              options: [
                "Tableware",
                "Cutlery",
                "Glassware",
                "Cookware",
                "Cooking Utensils",
                "Small Appliances",
                "Bakeware",
                "Food Storage Containers",
                "Kitchen Equipment",
                "Cutting Boards",
                "Serving Utensils",
                "Trays",
                "Barware"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-cip-cip",
                  type: QuestionDependencyType.Silent
                }
              ]
            },
            {
              id: "warewashing-cip-cip-methods",
              reportWording:
                "Below is the frequency and type of chemical sanitizer used. Also, how and when the concentration will be tested:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "What frequency and type of chemical sanitizer is used? How and when will the concentration be tested?",
              options: [
                "Chlorine: According to manufacturer guidelines and Safety Data Sheets",
                "Quaternary Ammonium (Quat): According to manufacturer guidelines and Safety Data Sheets",
                "Acids: According to manufacturer guidelines and Safety Data Sheets"
              ],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "warewashing-cip-cip",
                  type: QuestionDependencyType.Silent
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: "food-contamination",
      urlSlug: "food-contamination",
      name: "Food Contamination",
      pages: [
        {
          id: "food-contamination-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "food-contamination-proper-use-understand",
              reportWording: "We understand the rules for protecting food from contamination:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for protecting food from contamination?",
              options: [],
              contentFile: "/SOPContext/ProtectingFoodContamination.md",
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "food-contamination-training-pic",
              reportWording: "Below is who will be responsible for training employees on food contamination:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on food contamination, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "food-contamination-training-training-methods",
              reportWording: "Below are the methods for training employees on bare hand contact:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on food contamination",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "food-contamination-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when food contamination is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when food contamination is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-cold-food",
          urlSlug: "cold-food",
          name: "Cold Food",
          header: "",
          questions: [
            {
              id: "food-contamination-cold-food-cold-storage",
              reportWording: "Below are how cold food will be stored to prevent contamination:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Select how cold food will be stored to prevent contamination.",
              options: [
                "Reach in refrigeration",
                "Walk in refrigeration",
                "Storing raw animal foods away from ready-to-eat food, cooked food and produce.",
                "Separating raw beef, fish, lamb, pork and poultry from each other unless intentionally mixing during preparation",
                "Storing raw, unwashed produce away from washed produce and other ready-to-eat foods.",
                "Storing the food in packages, covered containers, or wrappings."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-dry-goods",
          urlSlug: "dry-goods",
          name: "Dry Goods",
          header: "",
          questions: [
            {
              id: "food-contamination-dry-goods-dry-storage",
              reportWording: "Below are how dry goods will be stored to prevent contamination:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Select how dry goods will be stored to prevent contamination.",
              options: [
                "Storing the food in packages, covered containers, or wrappings.",
                "Storing food: In a clean, dry location where it is not exposed to splash, dust, or other contamination; and at least 6 inches above the floor",
                "Individual packaging",
                "Display case",
                "Vending machine"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-display-foods",
          urlSlug: "display-foods",
          name: "Display Foods",
          header: "",
          questions: [
            {
              id: "food-contamination-display-foods-display-storage",
              reportWording: "Below are how food on display/self service will be stored to prevent contamination:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Select how food on display/self service be stored to prevent contamination",
              options: [
                "Providing food shields, covers, or other effective means of protection for food on display or provided for self-service.",
                "Providing tongs",
                "Providing deli paper",
                "Providing ladle",
                "Providing spoon"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-in-use-utensils",
          urlSlug: "in-use-utensils",
          name: "In-Use Utensils",
          header: "",
          questions: [
            {
              id: "food-contamination-in-use-utensils-in-use-utensils",
              reportWording:
                "Below are how and where in-use utensils will be stored during pauses in preparation or dispensing:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Select how and where in-use utensils will be stored during pauses in preparation or dispensing? ",
              options: [
                "Using separate utensils and preparation surfaces for preparing raw foods and ready-to-eat foods.",
                "Appropriately storing in-use utensils and cleaning them at required frequency (within 4 hours).",
                "Not using a utensil more than once to taste food that is to be sold or served."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-contamination-prevention",
          urlSlug: "contamination-prevention",
          name: "Contamination Prevention",
          header: "",
          questions: [
            {
              id: "food-contamination-contamination-prevention-cross-contamination",
              reportWording:
                "Below are how contamination will be prevented when utensils and services are used for both raw animal foods and ready-to-eat foods:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Select how contamination will be prevented when utensils and services are used for both raw animal foods and ready-to-eat foods",
              options: [
                "Keeping cutting boards, slicers, utensils and other food contact surfaces of equipment cleaned and sanitized.",
                "Storing wet wiping cloths in containers of proper EPA registered sanitizer solutions at concentrations that meet the manufacturer's label instructions.",
                "Cleaning between designated tasks",
                "Preparing TCS foods before RTE foods"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-clean-tableware",
          urlSlug: "clean-tableware",
          name: "Clean Tableware",
          header: "",
          questions: [
            {
              id: "food-contamination-clean-tableware-clean-tableware",
              reportWording:
                "Below are how contamination of clean tableware and utensils, including those utensils used for self-service food operation will be prevented:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "How will contamination of clean tableware and utensils, including those utensils used for self-service food operation be prevented?",
              options: [
                "Proper Storage: Store in covered, clean, and dry areas.",
                "Separation: Clearly separate clean and used utensils.",
                "Handling: Handle utensils by the handles only.",
                "Dispensers: Use utensil dispensers to minimize customer contact.",
                "Regular Sanitization: Frequently clean and sanitize storage and display areas.",
                "Tongs or Gloves: Staff should use tongs or gloves for handling.",
                "Signage: Post signs instructing proper utensil handling by customers."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-buffet",
          urlSlug: "buffet",
          name: "Buffet",
          header: "",
          questions: [
            {
              id: "food-contamination-buffet-buffet",
              reportWording:
                "Below are how contamination of clean tableware and utensils, including those utensils used for self-service food operation (e.g. buffet) will be prevented:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Select how contamination of clean tableware and utensils, including those utensils used for self-service food operation (e.g. buffet) will be prevented?",
              options: [
                "Proper Storage: Store in covered, clean, and dry areas.",
                "Separation: Clearly separate clean and used utensils.",
                "Handling: Handle utensils by the handles only.",
                "Dispensers: Use utensil dispensers to minimize customer contact.",
                "Regular Sanitization: Frequently clean and sanitize storage and display areas.",
                "Tongs or Gloves: Staff should use tongs or gloves for handling.",
                "Signage: Post signs instructing proper utensil handling by customers.",
                "Provide supervisor or attendant"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "food-contamination-wet-cloths",
          urlSlug: "wet-cloths",
          name: "Wet Cloths",
          header: "",
          questions: [
            {
              id: "food-contamination-wet-cloths-wet-cloths",
              reportWording: "Below is how wet wiping cloths be stored, including the type of sanitizer to be used:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will wet wiping cloths be stored? Include the type of sanitizer to be used.",
              options: [
                "Storing wet wiping cloths in containers of proper EPA registered sanitizer solutions at concentrations that meet the manufacturer's label instructions."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "date-marking",
      urlSlug: "date-marking",
      name: "Date Marking",
      pages: [
        {
          id: "date-marking-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "date-marking-proper-use-understand",
              reportWording:
                "We understand the rules for date marking Ready-To-Eat (RTE), time/temperature control for safety food:",
              type: QuestionType.ConfirmContext,
              prompt:
                "Do you understand the rules for date marking Ready-To-Eat (RTE), time/temperature control for safety food?",
              contentFile: "/SOPContext/DateMarking.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "date-marking-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "date-marking-training-pic",
              reportWording: "Below is who will be responsible for training employees on date marking:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on date marking, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "date-marking-training-training-methods",
              reportWording: "Below are the methods for training employees on date marking:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on date marking",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "date-marking-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "date-marking-corrective-action-corrective-action",
              reportWording: "Below are the corrective action(s) that will be used when date marking is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when date marking is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "date-marking-hold-time",
          urlSlug: "hold-time",
          name: "Hold Time",
          header: "",
          questions: [
            {
              id: "date-marking-hold-time-food-items",
              reportWording:
                "Below are all the ready-to-eat TCS foods (or groups of food) that you will be date marking and the length of time you will be holding these foods:",
              prompt:
                "List all ready-to-eat TCS foods (or groups of food) that you will be date marking and the length of time you will be holding these foods.",
              required: true,
              options: ["4 hours", "6 hours", "1 day", "2 days", "3 days", "4 days", "5 days", "6 days", "7 days"],
              type: QuestionType.AccordionMultiSelectDepsAsOptions,
              dependencies: [
                {
                  questionId: "restaurant-information-food-items-food",
                  type: QuestionDependencyType.Required,
                  label: "Food"
                }
              ],
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "date-marking-marking-system",
          urlSlug: "marking-system",
          name: "Marking System",
          header: "",
          questions: [
            {
              id: "date-marking-marking-system-marking",
              reportWording:
                "Below are the type of date marking system to be used (e.g. calendar dates, days of the week, color coded marks, etc.) and whether this date marking system is indicating the date of preparation, date of discard, or both:",
              type: QuestionType.Textarea,
              prompt:
                "Describe the type of date marking system to be used (e.g. calendar dates, days of the week, color coded marks, etc.) and whether this date marking system is indicating the date of preparation, date of discard, or both.",
              options: [
                "We will be using labels from a vendor that will have the product name, calendar dates with preparation and discard dates noted."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "time-controls",
      urlSlug: "time-controls",
      name: "Time Controls",
      pages: [
        {
          id: "time-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "time-controls-proper-use-understand",
              reportWording: "We understand time as a public health control:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand time as a public health control?",
              contentFile: "/SOPContext/TimeControls.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "bare-hand-contact-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "time-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on time controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on time controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "time-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on time controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on time controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "time-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "time-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when time controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when time controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "time-controls-food-list",
          urlSlug: "food-list",
          name: "Food List",
          header: "",
          questions: [
            {
              id: "time-controls-food-list-hold-temp",
              reportWording:
                "Below are all the TCS (Time Control Sensitive) foods that we intend to hold between 41°F and 135°F, using time only as a control (no temperature):",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "List all TCS (Time Control Sensitive) foods that you intend to hold between 41°F and 135°F, using time only as a control (no temperature).",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "time-controls-method",
          urlSlug: "method",
          name: "Method",
          header: "",
          questions: [
            {
              id: "time-controls-method-method",
              reportWording: "Below are the time only control methods you will be utilizing:",
              type: QuestionType.Multiselect,
              prompt: "Mark which time only control method you will be utilizing?",
              options: ["4-hour maximum time period", "6-hour maximum time period"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "time-controls-procedure",
          urlSlug: "procedure",
          name: "Procedure",
          header: "",
          questions: [
            {
              id: "time-controls-procedure-procedure",
              reportWording:
                "Below are your procedure for the time period you marked including how you will mark or identify the foods listed: monitoring of temperature (if applicable); and what will be done with food that has reached its time limit, is not marked, or remains past the allowed timeframe:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Describe your procedure for the time period you marked including how you will mark or identify the foods listed: monitoring of temperature (if applicable); and what will be done with food that has reached its time limit, is not marked, or remains past the allowed timeframe.",
              options: [
                "Marking or Identifying Foods - Date Labels: Use color-coded labels or stickers with the date and time of preparation, and clearly write the 'use by' date.",
                "Marking or Identifying Foods - Rotation System: Place new items behind existing ones to ensure older products are used first (FIFO: First In, First Out).",
                "Marking or Identifying Foods - Digital Tracking: Use a digital system, if available, to log the date and time for each TCS (Time/Temperature Control for Safety) food item.",
                "Monitoring of Temperature - Regular Checks: Use a calibrated digital thermometer to check temperatures of hot- and cold-held foods at least every two hours.",
                "Handling Food that Reaches its Time Limit - Immediate Disposal: Discard any food that has reached its marked expiration time to prevent accidental use or service.",
                "Handling Food that Reaches its Time Limit - Unmarked or Incorrectly Marked Food: If food is found unmarked or incorrectly labeled, it will be discarded immediately to avoid safety risks.",
                "Handling Food that Reaches its Time Limit - Extended Storage: If food remains past the allowed time frame (7 days for most ready-to-eat TCS foods), it will be removed from service and discarded."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "thawing-controls",
      urlSlug: "thawing-controls",
      name: "Thawing Controls",
      pages: [
        {
          id: "thawing-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "thawing-controls-proper-use-understand",
              reportWording: "We understand thawing time and temperature foods as a public health control:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand thawing time and temperature foods as a public health control?",
              contentFile: "/SOPContext/ThawingControl.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "thawing-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on thawing controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on thawing controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "thawing-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on thawing controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on thawing controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "thawing-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when thawing controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when thawing controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-refrigerator",
          urlSlug: "refrigerator",
          name: "Refrigerator",
          header: "",
          questions: [
            {
              id: "thawing-controls-refrigerator-thawing-41",
              reportWording:
                "Below are the foods (or groups of food) we will be thawing in a refrigerator at 41°F or below:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List what foods (or groups of food) you will be thawing in a refrigerator at 41°F or below.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-submerged",
          urlSlug: "submerged",
          name: "Submerged",
          header: "",
          questions: [
            {
              id: "thawing-controls-submerged-thawing-70",
              reportWording:
                "Below are the foods (or groups of food) we will be thawing by submerging under running water at 70°F or below, with sufficient flow to remove food particles:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "List what foods (or groups of food) you will be thawing by submerging under running water at 70°F or below, with sufficient flow to remove food particles. ",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-microwave",
          urlSlug: "microwave",
          name: "Microwave",
          header: "",
          questions: [
            {
              id: "thawing-controls-microwave-thawing-microwave",
              reportWording:
                "Below are the foods (or groups of food) we will be thawing in a microwave only if the food is immediately placed in conventional cooking equipment as part of the continuous cooking process:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "List what foods (or groups of food) you will be thawing in a microwave only if the food is immediately placed in conventional cooking equipment as part of the continuous cooking process.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "thawing-controls-conventional",
          urlSlug: "conventional",
          name: "Conventional",
          header: "",
          questions: [
            {
              id: "thawing-controls-conventional-thawing-conventional",
              reportWording:
                "Below are the foods (or groups of food) we will be thawing in conventional cooking equipment while in a frozen state:",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "List what foods (or groups of food) you will be thawing in conventional cooking equipment while in a frozen state.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "cooking-controls",
      urlSlug: "cooking-controls",
      name: "Cooking Controls",
      pages: [
        {
          id: "cooking-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "cooking-controls-proper-use-understand",
              reportWording: "We understand cooking time/temp for safety food:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand cooking time/temp for safety food?",
              contentFile: "/SOPContext/CookingControls.md",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "cooking-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on cooking controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on cooking controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cooking-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on cooking controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on cooking controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "cooking-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when cooking controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when cooking controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-165-degrees-fahrenheit",
          urlSlug: "165-degrees-fahrenheit",
          name: "Cooking at 165°F",
          header: "",
          questions: [
            {
              id: "cooking-controls-165-degrees-fahrenheit-cooking-165",
              reportWording: "Below are the foods (or groups of food) that will be cooked in this facility to 165°F:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all foods (or groups of food) that will be cooked in this facility to 165°F.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              contentFile: "/SOPContext/CookingControls.md",
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-155-degrees-fahrenheit",
          urlSlug: "155-degrees-fahrenheit",
          name: "Cooking at 155°F",
          header: "",
          questions: [
            {
              id: "cooking-controls-155-degrees-fahrenheit-cooking-155",
              reportWording: "Below are the foods (or groups of food) that will be cooked in this facility to 155°F:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all foods (or groups of food) that will be cooked in this facility to 155°F.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              contentFile: "/SOPContext/CookingControls.md",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-145-degrees-fahrenheit",
          urlSlug: "145-degrees-fahrenheit",
          name: "Cooking at 145°F",
          header: "",
          questions: [
            {
              id: "cooking-controls-145-degrees-fahrenheit-cooking-145",
              reportWording: "Below are the foods (or groups of food) that will be cooked in this facility to 145°F:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all foods (or groups of food) that will be cooked in this facility to 145°F.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              contentFile: "/SOPContext/CookingControls.md",
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-135-degrees-fahrenheit",
          urlSlug: "135-degrees-fahrenheit",
          name: "Cooking at 135°F",
          header: "",
          questions: [
            {
              id: "cooking-controls-135-degrees-fahrenheit-cooking-135",
              reportWording: "Below are the foods (or groups of food) that will be cooked in this facility to 135°F:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all foods (or groups of food) that will be cooked in this facility to 135°F.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              contentFile: "/SOPContext/CookingControls.md",
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooking-controls-roasts",
          urlSlug: "roasts",
          name: "Cooking Whole Meat Roasts",
          header: "",
          dependencies: [
            { questionId: "restaurant-information-equipment-list-roasting", type: QuestionDependencyType.Silent }
          ],
          questions: [
            {
              id: "restaurant-information-equipment-list-roasting",
              reportWording: "Below is if you will be roasting whole meats:",
              type: QuestionType.YesNo,
              prompt: "Are you roasting whole meats?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              // this is a hack to show the roasting equipment question on the report even though it was answered in the equipment review section
              dependencies: [{ questionId: "restaurant-information-equipment", type: QuestionDependencyType.Silent }]
            },
            {
              id: "restaurant-information-equipment-list-roasting-equipment",
              reportWording: "What equipment are you roasting whole meats in?",
              type: QuestionType.Textarea,
              prompt: "What equipment are you roasting whole meats in?",
              options: [],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                { questionId: "restaurant-information-equipment-list-roasting", type: QuestionDependencyType.Silent },
                // this is a hack to show the roasting equipment question on the report even though it was answered in the equipment review section
                { questionId: "restaurant-information-equipment", type: QuestionDependencyType.Silent }
              ]
            },
            {
              id: "cooking-controls-roasts-roasts",
              reportWording: "Below are the cooking times you intend to use for whole meat roasts:",
              type: QuestionType.Textarea,
              prompt: "Cooking Times - Describe the cooking times you intend to use for whole meat roasts.",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                { questionId: "restaurant-information-equipment-list-roasting", type: QuestionDependencyType.Silent }
              ]
            },
            {
              id: "cooking-controls-roasts-temp",
              reportWording: "Below are the cooking temperatures you intend to use for whole meat roasts:",
              type: QuestionType.Textarea,
              prompt: "Temperatures - Describe the cooking temperatures you intend to use for whole meat roasts.",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                { questionId: "restaurant-information-equipment-list-roasting", type: QuestionDependencyType.Silent }
              ]
            }
          ]
        },
        {
          id: "cooking-controls-raw-animal-products",
          urlSlug: "raw-animal-products",
          name: "Raw Animal Products",
          header: "",
          questions: [
            {
              id: "cooking-controls-raw-animal-products-question",
              reportWording: "Below is if we will be serving raw or undercooked animal foods:",
              type: QuestionType.YesNo,
              prompt: "Are you serving raw or undercooked animal foods? ",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cooking-controls-raw-animal-products-products",
              reportWording: "Below are the raw animal products you will be serving raw or undercooked:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List the raw animal products you will be serving raw or undercooked.",
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required },
                { questionId: "cooking-controls-raw-animal-products-question", type: QuestionDependencyType.Silent }
              ]
            },
            {
              id: "cooking-controls-raw-animal-products-notify",
              reportWording:
                "Below are how we will notify consumers of the risk of eating raw or undercooked animal products:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how you will notify consumers of the risk of eating raw or undercooked animal products.",
              options: ["We will put a consumer warning on the menu."],
              required: false,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                { questionId: "cooking-controls-raw-animal-products-question", type: QuestionDependencyType.Silent }
              ]
            }
          ]
        },
        {
          id: "cooking-controls-thermometer",
          urlSlug: "thermometer",
          name: "Thermometer",
          header: "",
          questions: [
            {
              id: "cooking-controls-thermometer-thermometer",
              reportWording:
                "Below are how we will monitor cooking temperatures and times including the type of food thermometer used:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how and when cooking temperatures and times will be monitored including the type of food thermometer used.",
              options: [
                "Temperatures will be checked at the thickest part of the food, at regular intervals and at it's final cooking stage with a calibrated probe thermometer."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "cooling-controls",
      urlSlug: "cooling-controls",
      name: "Cooling Controls",
      pages: [
        {
          id: "cooling-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "cooling-controls-proper-use-understand",
              reportWording: "We understand cooling time/temp control for safety food:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand cooling time/temp control for safety food?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/CoolingControls.md"
            }
          ]
        },
        {
          id: "cooling-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "cooling-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on cooling controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on cooling controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cooling-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on cooling controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on cooling controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooling-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "cooling-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when cooling controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when cooling controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooling-controls-hot-temp",
          urlSlug: "hot-temp",
          name: "Hot Temp",
          header: "",
          questions: [
            {
              id: "cooling-controls-hot-temp-hot",
              reportWording:
                "Below are the TCS foods (or groups of foods) we will be rapidly cooling from a hot temperature and the cooling method we will use for each listed item:",
              prompt:
                "List all the TCS foods (or groups of foods) you will be rapidly cooling from a hot temperature and the cooling method you will use for each listed item.",
              required: true,
              options: ["Not Applicable", ...CoolingMethods],
              type: QuestionType.AccordionMultiSelectDepsAsOptions,
              dependencies: [
                {
                  questionId: "restaurant-information-food-items-food",
                  type: QuestionDependencyType.Required,
                  label: "Food"
                }
              ],
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooling-controls-ambient-temp",
          urlSlug: "ambient-temp",
          name: "Ambient Temp",
          header: "",
          questions: [
            {
              id: "cooling-controls-ambient-temp-ambient",
              reportWording:
                "Below are the TCS foods (or groups of foods) we will be rapidly cooling from ambient (room temperature) and the cooling method we will use for each listed item:",
              prompt:
                "List all the TCS foods (or groups of foods) you will be rapidly cooling from ambient (room temperature) and the cooling method you will use for each listed item.",
              options: ["Not Applicable", ...CoolingMethods],
              type: QuestionType.AccordionMultiSelectDepsAsOptions,
              dependencies: [
                {
                  questionId: "restaurant-information-food-items-food",
                  type: QuestionDependencyType.Required,
                  label: "Food"
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cooling-controls-monitoring",
          urlSlug: "monitoring",
          name: "Monitoring",
          header: "",
          questions: [
            {
              id: "cooling-controls-monitoring-monitor",
              reportWording:
                "Below are how we will monitor cooling temperatures and the times including the type of food thermometer used:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how and when cooling temperatures and the times will be monitored including the type of food thermometer used.",
              options: [
                "Temperatures will be checked during the cooling process at set two-hour intervals. A calibrated digital probe thermometer will be used and temperatures will be entered into cooling logs."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "reheating-controls",
      urlSlug: "reheating-controls",
      name: "Reheating Controls",
      pages: [
        {
          id: "reheating-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "reheating-controls-proper-use-understand",
              reportWording: "We understand reheating for hot holding time & temperature control for safety foods:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand reheating for hot holding time & temperature control for safety foods?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/ReheatingControls.md"
            }
          ]
        },
        {
          id: "reheating-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "reheating-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on reheating controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on reheating controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "reheating-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on reheating controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on reheating controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "reheating-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "reheating-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when reheating controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when reheating controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "reheating-controls-165-degrees-fahrenheit",
          urlSlug: "165-degrees-fahrenheit",
          name: "165°F",
          header: "",
          questions: [
            {
              id: "reheating-controls-165-degrees-fahrenheit-holding",
              reportWording: "Below are the foods (or groups of foods) that will be reheated to 165°F for hot holding:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Choose all foods (or groups of foods) that will be reheated to 165°F for hot holding.",
              options: ["Foods previously cooked to 165F"],
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "reheating-controls-135-degrees-fahrenheit",
          urlSlug: "135-degrees-fahrenheit",
          name: "135°F",
          header: "",
          questions: [
            {
              id: "reheating-controls-135-degrees-fahrenheit-holding",
              reportWording: "Below are the foods (or groups of foods) that will be reheated to 135°F for hot holding:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Choose all foods (or groups of foods) that will be reheated to 135°F for hot holding.",
              options: ["Foods previously cooked to 135F"],
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "reheating-controls-equipment",
          urlSlug: "equipment",
          name: "Equipment",
          header: "",
          questions: [
            {
              id: "reheating-controls-equipment-reheating",
              reportWording: "Below are the equipment we will utilize to reheat food for hot holding:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What equipment will be utilized to reheat food for hot holding?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ]
            }
          ]
        },
        {
          id: "reheating-controls-monitoring",
          urlSlug: "monitoring",
          name: "Monitoring",
          header: "",
          questions: [
            {
              id: "reheating-controls-monitoring-monitoring",
              reportWording:
                "Below are how we will monitor reheating temperatures and times including the type of food thermometer used:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how and when reheating temperatures and times will be monitored including the type of food thermometer used.",
              options: [
                "Temperatures will be checked throughout the reheating process with a calibrated probe thermometer. Once the temperature reaches the proper temperature (135/165) it will be documented in a temperature log."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "hot-holding-controls",
      urlSlug: "hot-holding-controls",
      name: "Hot Holding Controls",
      pages: [
        {
          id: "hot-holding-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-proper-use-understand",
              reportWording: "We understand hot holding time & temperature control for safety foods:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand hot holding time & temperature control for safety foods?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/HotHoldingControls.md"
            }
          ]
        },
        {
          id: "hot-holding-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on hot holding controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on hot holding controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "hot-holding-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on hot holding controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on hot holding controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "hot-holding-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when hot holding controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when hot holding controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "hot-holding-controls-foods",
          urlSlug: "foods",
          name: "Foods",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-foods-foods",
              reportWording: "Below are the TCS (Time Control Sensitive) foods that will be held hot:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all TCS (Time Control Sensitive) foods that will be held hot.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "hot-holding-controls-equipment",
          urlSlug: "equipment",
          name: "Equipment",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-equipment-equipment",
              reportWording: "Below are the equipment we will use for hot holding TCS foods:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all equipment that will be used for hot holding TCS foods",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "hot-holding-controls-monitoring",
          urlSlug: "monitoring",
          name: "Monitoring",
          header: "",
          questions: [
            {
              id: "hot-holding-controls-monitoring-monitoring",
              reportWording:
                "Below are how we will monitor hot holding temperatures including the type of food thermometer used:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how and when hot holding temperatures will be monitored including the type of food thermometer used.",
              options: [
                "Temperatures will be checked throughout the hot holding process with a calibrated probe thermometer. Product will be continuously stirred and covered ensuring a minimum of 135 degrees and documented in a temperature log."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "cold-holding-controls",
      urlSlug: "cold-holding-controls",
      name: "Cold Holding Controls",
      pages: [
        {
          id: "cold-holding-controls-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-proper-use-understand",
              reportWording: "We understand cold holding time & temperature control for safety foods:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand cold holding time & temperature control for safety foods?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/ColdHoldingControls.md"
            }
          ]
        },
        {
          id: "cold-holding-controls-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-training-pic",
              reportWording: "Below is who will be responsible for training employees on cold holding controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on cold holding controls, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cold-holding-controls-training-training-methods",
              reportWording: "Below are the methods for training employees on cold holding controls:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on cold holding controls",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cold-holding-controls-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when cold holding controls are not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when cold holding controls are not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cold-holding-controls-foods",
          urlSlug: "foods",
          name: "Foods",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-foods-foods",
              reportWording: "Below are the TCS (Time Control Sensitive) foods that will be held cold and/or frozen:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all TCS (Time Control Sensitive) foods that will be held cold and/or frozen.",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cold-holding-controls-equipment",
          urlSlug: "equipment",
          name: "Equipment",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-equipment-equipment",
              reportWording: "Below are the equipment we will use for cold holding TCS foods:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List all equipment that will be used for cold holding TCS foods",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cold-holding-controls-ice",
          urlSlug: "ice",
          name: "Ice",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-ice-intro",
              reportWording: "Below is if we will use ice to hold TCS food:",
              type: QuestionType.YesNo,
              prompt: "Will ice be used to hold TCS food?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cold-holding-controls-ice-foods",
              reportWording: "Below are the TCS food we will be held on ice:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What TCS food will be held on ice?",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required },
                { questionId: "cold-holding-controls-ice-intro", type: QuestionDependencyType.Silent }
              ],
              required: false,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "cold-holding-controls-ice-where",
              reportWording: "Below are where and how long TCS food will be held:",
              type: QuestionType.Textarea,
              prompt: "Where and how long it will be held?",
              options: ["No more than 6 hours."],
              required: false,
              dependencies: [{ questionId: "cold-holding-controls-ice-intro", type: QuestionDependencyType.Silent }],
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "restaurant-information-equipment-list-ice",
              reportWording: "Below is the source of the ice:",
              type: QuestionType.Custom,
              prompt: "What is the source of the ice?",
              options: [],
              dependencies: [
                { questionId: "cold-holding-controls-ice-intro", type: QuestionDependencyType.Silent },
                { questionId: "cold-holding-controls", type: QuestionDependencyType.Silent }
              ],
              required: false,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "cold-holding-controls-monitoring",
          urlSlug: "monitoring",
          name: "Monitoring",
          header: "",
          questions: [
            {
              id: "cold-holding-controls-monitoring-monitoring",
              reportWording:
                "Below are how we will monitor cold holding temperatures including the type of food thermometer used:",
              type: QuestionType.Textarea,
              prompt:
                "Describe how and when cold holding temperatures will be monitored including the type of food thermometer used.",
              options: [
                "Temperatures will be checked throughout the hot cold process ensuring under 41 degrees. Calibrated probe thermometers and/or ambient air refrigerator and freezer thermometers will be used . Temperatures will be recorded in a temperature log."
              ],
              // dependencies: [{ questionId: "cold-holding-controls-ice-intro", type: QuestionDependencyType.Silent }],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "catering-offsite",
      urlSlug: "catering-offsite",
      name: "Catering & Offsite",
      dependencies: [
        {
          questionId: "restaurant-information-catering-catering",
          type: QuestionDependencyType.Silent
        }
      ],
      pages: [
        {
          id: "catering-offsite-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "catering-offsite-proper-use-understand",
              reportWording: "We understand the rules for catering, off-site and/or satellite food service:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for catering, off-site and/or satellite food service?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/CateringOffsite.md"
            }
          ]
        },
        {
          id: "catering-offsite-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "catering-offsite-training-pic",
              reportWording: "Below is who will be responsible for training employees on catering offsite:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on catering offsite, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "catering-offsite-training-training-methods",
              reportWording: "Below are the methods for training employees on catering offsite:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on catering offsite",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "catering-offsite-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when catering offsite is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when catering offsite is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-foods",
          urlSlug: "foods",
          name: "Foods",
          header: "",
          questions: [
            {
              id: "catering-offsite-foods-foods",
              reportWording:
                "Below are the foods (or groups of food) that will be served off-site from your establishment:",
              type: QuestionType.MultiselectWithOther,
              prompt: "List the foods (or groups of food) that will be served off-site from your establishment",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-vehicle",
          urlSlug: "vehicle",
          name: "Vehicle",
          header: "",
          questions: [
            {
              id: "catering-offsite-vehicle-vehicle",
              reportWording: "Below is the type of vehicle we will use to transport food:",
              type: QuestionType.Textarea,
              prompt: "What type of vehicle will be used to transport food?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-hot-holding-equipment",
          urlSlug: "hot-holding-equipment",
          name: "Hot Holding Equipment",
          header: "",
          questions: [
            {
              id: "catering-offsite-hot-holding-equipment-equipment",
              reportWording: "Below are the equipment we will use to hold hot food:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What equipment will be used to hold hot food?",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-hot-holding-monitoring",
          urlSlug: "hot-holding-monitoring",
          name: "Hot Holding Monitoring",
          header: "",
          questions: [
            {
              id: "catering-offsite-hot-holding-monitoring-monitor",
              reportWording: "Below are how we will monitor hot TCS food:",
              type: QuestionType.Textarea,
              prompt: "How will hot TCS food be monitored? ",
              options: [
                "All food temperatures will be logged and recorded before being packaged to travel to ensure they have reached the proper temperature. We will also check the temperature upon arrival. The time will be logged to ensure communication to the vendor to discard at a maximum of 4 hours."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-cold-holding-equipment",
          urlSlug: "cold-holding-equipment",
          name: "Cold Holding Equipment",
          header: "",
          questions: [
            {
              id: "catering-offsite-cold-holding-equipment-equipment",
              reportWording: "Below are the equipment we will use to hold cold food:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What equipment will be used to hold cold food?",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-cold-holding-monitoring",
          urlSlug: "cold-holding-monitoring",
          name: "Cold Holding Monitoring",
          header: "",
          questions: [
            {
              id: "catering-offsite-cold-holding-monitoring-monitor",
              reportWording: "Below are how we will monitor cold TCS food:",
              type: QuestionType.Textarea,
              prompt: "How will cold TCS food be monitored? ",
              options: [
                "All food temperatures will be logged and recorded before being packaged to travel to ensure they have reached the proper temperature. We will also check the temperature upon arrival. The time will be logged to ensure communication to the vendor to discard at a maximum of 4 hours."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "catering-offsite-food-protection",
          urlSlug: "food-protection",
          name: "Food Protection",
          header: "",
          questions: [
            {
              id: "catering-offsite-food-protection-protection",
              reportWording: "Below are how food will be protected at off-site locations:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Describe how food will be protected at off-site locations.",
              options: [
                "Food shields",
                "Covered",
                "Chaffing dishes with lids",
                "Togo boxes",
                "Protective equipment",
                "Tongs and Ladle"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "outdoor-dining",
      urlSlug: "outdoor-dining",
      name: "Outdoor Dining",
      dependencies: [
        {
          questionId: "restaurant-information-outdoor-outdoor-dining",
          type: QuestionDependencyType.Silent
        }
      ],
      pages: [
        {
          id: "outdoor-dining-proper-use",
          urlSlug: "proper-use",
          name: "Proper Use",
          header: "",
          questions: [
            {
              id: "outdoor-dining-proper-use-understand",
              reportWording: "We understand the rules for outdoor/exposed dining and/or food preparation:",
              type: QuestionType.ConfirmContext,
              prompt: "Do you understand the rules for outdoor/exposed dining and/or food preparation?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              contentFile: "/SOPContext/OutdoorDining.md"
            }
          ]
        },
        {
          id: "outdoor-dining-training",
          urlSlug: "training",
          name: "Training",
          header: "",
          questions: [
            {
              id: "outdoor-dining-training-pic",
              reportWording: "Below is who will be responsible for training employees on outdoor dining:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt:
                "Who will be responsible for training employees on outdoor dining, and disciplining them when they are not followed?",
              options: trainingPic,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-pic",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "outdoor-dining-training-training-methods",
              reportWording: "Below are the methods for training employees on outdoor dining:",
              type: QuestionType.MultiselectPreselectedFromDependencies,
              prompt: "Describe the methods for training employees on outdoor dining",
              options: trainingMethods,
              dependencies: [
                {
                  questionId: "pic-training-discipline-training-training-methods",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-corrective-action",
          urlSlug: "corrective-action",
          name: "Corrective Action",
          header: "",
          questions: [
            {
              id: "outdoor-dining-corrective-action-corrective-action",
              reportWording:
                "Below are the corrective action(s) that will be used when outdoor dining is not followed:",
              type: QuestionType.TextareaFromDependencies,
              prompt: "What is the corrective action that will be used when outdoor dining is not followed?",
              dependencies: [
                {
                  questionId: "pic-training-discipline-corrective-action-corrective-action",
                  type: QuestionDependencyType.Required
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-interior-kitchen",
          urlSlug: "interior-kitchen",
          name: "Interior Kitchen",
          header: "",
          questions: [
            {
              id: "outdoor-dining-interior-kitchen-kitchen-protection",
              reportWording: "Below are protection of the interior kitchen:",
              type: QuestionType.MultiselectWithOther,
              prompt: "Please describe protection of the interior kitchen",
              options: [
                "Overhead Coverage: Ensure that the kitchen has adequate overhead protection, such as a roof or canopy, to prevent contamination from rain, debris, or pests.",
                "Screening: Install screened doors and windows to keep out insects and pests.",
                "Doors and Windows: Ensure doors and windows are kept closed when not in use, or use air curtains to prevent pests from entering.",
                "Enclosures: Use walls or partial enclosures to protect from wind, dust, and other outdoor elements.",
                "Pest Control: Implement pest control measures such as traps, baits, or professional pest management.",
                "Sanitation: Maintain a strict cleaning and sanitizing schedule to reduce contamination risks from outdoor exposure."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-foods",
          urlSlug: "foods",
          name: "Foods",
          header: "",
          questions: [
            {
              id: "outdoor-dining-foods-held-area-foods",
              reportWording: "Below are the types of foods that will be held in this area:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What types of foods will be held in this area?",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            },
            {
              id: "outdoor-dining-foods-prepared-area-foods",
              reportWording: "Below are the foods that will be prepared outdoors:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What foods will be prepared outdoors?",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-food-protections",
          urlSlug: "food-protections",
          name: "Food Protections",
          header: "",
          questions: [
            {
              id: "outdoor-dining-food-protections-food-protected",
              reportWording: "Below are how foods will be protected:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will foods be protected?",
              options: [
                "Covered Containers: Use covered or sealed containers to protect food from dust, insects, and outdoor elements.",
                "Chafing Dishes: Use covered chafing dishes to keep hot foods protected and maintain proper temperatures.",
                "Sneeze Guards: Install sneeze guards at buffet or service stations.",
                "Food Wrap: Wrap individual items in plastic or foil to prevent exposure.",
                "Temperature Control: Use insulated containers or refrigerated units to keep cold foods below 41°F and hot foods above 135°F.",
                "Staff Supervision: Ensure staff monitor the area to prevent customer contamination and maintain cleanliness.",
                "Barrier around cooking equipment."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-utensils",
          urlSlug: "utensils",
          name: "Utensils",
          header: "",
          questions: [
            {
              id: "outdoor-dining-utensils-outside-utensils",
              reportWording: "Below are the utensils that will be present in this area:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What utensils will be present in this area?",
              options: [
                "Serving tongs, spoons, spatulas",
                "Forks, knives, spoons for diners",
                "Beverage dispensers and cups",
                "Napkins and condiments"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-equipment",
          urlSlug: "equipment",
          name: "Equipment",
          header: "",
          questions: [
            {
              id: "outdoor-dining-equipment-outside-equipment",
              reportWording: "Below are the equipment that will be present in this area:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What equipment will be present in this area?",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-u&e-protections",
          urlSlug: "u&e-protections",
          name: "U&E Protections",
          header: "",
          questions: [
            {
              id: "outdoor-dining-u&e-protections-ue-protections",
              reportWording: "Below are how the utensils and equipment will be protected:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will the utensils and equipment be protected? ",
              options: [
                "Covered Utensils: Store utensils in sealed or covered containers when not in use.",
                "Single-Use Utensils: Provide individually wrapped disposable utensils to prevent cross-contamination.",
                "Utensil Holders: Use utensil dispensers or holders to limit exposure to dust and pests.",
                "Regular Cleaning: Clean and sanitize utensils and equipment frequently, especially between uses.",
                "Food Shields/Sneeze Guards: Install sneeze guards to protect food serving stations from contamination.",
                "Temperature Control: Keep hot and cold food equipment properly regulated to avoid bacterial growth.",
                "Proper Storage: Store food equipment under shelters or in enclosures when not actively being used."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    },
    {
      id: "outdoor-food-preparation",
      urlSlug: "outdoor-food-preparation",
      name: "Outdoor Food Preparation",
      dependencies: [
        {
          questionId: "restaurant-information-outdoor-outdoor-prep",
          type: QuestionDependencyType.Silent
        }
      ],
      pages: [
        {
          id: "outdoor-dining-overhead",
          urlSlug: "overhead",
          name: "Overhead",
          header: "",
          questions: [
            {
              id: "outdoor-dining-overhead-overhead",
              reportWording: "Below are how food outdoors will be protected overhead:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food outdoors be protected overhead?",
              options: ["Food truck", "Canopies", "Tents", "Umbrellas", "Install permanent or temporary"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-flooring",
          urlSlug: "flooring",
          name: "Flooring",
          header: "",
          questions: [
            {
              id: "outdoor-dining-flooring-flooring",
              reportWording: "Below are how food will be protected via flooring:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected via flooring?",
              options: [
                "Food truck",
                "Non-porous, washable mats",
                "Tarps",
                "Avoid direct contact of food and food equipment with the ground by using raised surfaces or tables."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-walls-barriers",
          urlSlug: "walls-barriers",
          name: "Walls & Barriers",
          header: "",
          questions: [
            {
              id: "outdoor-dining-walls-barriers-barriers",
              reportWording: "Below are how food will be protected regarding walls and barriers:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected regarding walls and barriers?",
              options: [
                "Food truck",
                "Temporary walls, barriers, or partitions",
                "Install screened enclosures or mesh coverings"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-light-shielding",
          urlSlug: "light-shielding",
          name: "Light Shielding",
          header: "",
          questions: [
            {
              id: "outdoor-dining-light-shielding-light-shielding",
              reportWording: "Below are how food will be protected via light shielding:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected via light shielding?",
              options: ["Shatterproof or shielded light fixtures", "Install lighting with protective coverings"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-pest-control",
          urlSlug: "pest-control",
          name: "Pest Control",
          header: "",
          questions: [
            {
              id: "outdoor-dining-pest-control-pest-control",
              reportWording: "Below are how food will be protected regarding pest control:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected regarding pest control?",
              options: [
                "Employ natural or electronic pest deterrents (e.g., fans, air curtains)",
                "Use screens, mesh, or traps"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-food-coverings",
          urlSlug: "food-coverings",
          name: "Food Coverings",
          header: "",
          questions: [
            {
              id: "outdoor-dining-food-coverings-food-coverings",
              reportWording: "Below are how food will be protected by food coverings:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected by food coverings?",
              options: [
                "Use sealed, lidded containers or wrap food in plastic or foil to prevent exposure to air, insects, and contaminants.",
                "Cover food during transport and while not actively being prepared or served."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-handwashing-sanitation",
          urlSlug: "handwashing-sanitation",
          name: "Handwashing & Sanitation",
          header: "",
          questions: [
            {
              id: "outdoor-dining-handwashing-sanitation-sanitation",
              reportWording: "Below are how food will be protected:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be protected?",
              options: ["Portable handwashing stations with soap and water", "Sanitizing wipes", "Sanitizer dispenser"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-cold-holding-tcs-foods",
          urlSlug: "cold-holding-tcs-foods",
          name: "Cold Holding TCS Foods",
          header: "",
          questions: [
            {
              id: "outdoor-dining-cold-holding-tcs-foods-outdoor-cold",
              reportWording: "Below are how temperature control will be managed for cold holding TCS foods:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will temperature control be managed for cold holding TCS foods?",
              options: [
                "Refrigerated Coolers: Use portable coolers or refrigerated units to keep cold foods at 41°F or below.",
                "Ice Chests: Store cold foods in ice chests with sufficient ice to maintain proper temperatures.",
                "Ice Baths: Use ice baths for items like salads or desserts to ensure they stay below 41°F."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-hot-holding-tcs-foods",
          urlSlug: "hot-holding-tcs-foods",
          name: "Hot Holding TCS Foods",
          header: "",
          questions: [
            {
              id: "outdoor-dining-hot-holding-tcs-foods-outdoor-hot",
              reportWording: "Below are how temperature control will be managed for hot holding TCS foods:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will temperature control be managed for hot holding TCS foods?",
              options: [
                "Chafing Dishes: Use covered chafing dishes with fuel burners to keep hot foods at 135°F or above.",
                "Portable Warmers: Utilize portable electric warmers or insulated containers designed to hold food at safe hot temperatures.",
                "Grills or Stoves: Maintain food on grills or portable stoves until served, ensuring temperatures stay above 135°F."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-thermometer-monitoring",
          urlSlug: "thermometer-monitoring",
          name: "Thermometer Monitoring",
          header: "",
          questions: [
            {
              id: "outdoor-dining-thermometer-monitoring-outdoor-thermometers",
              reportWording: "Below are the thermometer(s) we will use:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What thermometer(s) will be used?",
              options: ["Digital Probe Thermometers:", "Ambient Air Thermometers"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-temperature-logs",
          urlSlug: "temperature-logs",
          name: "Temperature Logs",
          header: "",
          questions: [
            {
              id: "outdoor-dining-temperature-logs-outdoor-logs",
              reportWording: "Below are how temperature logs will be used:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will temperature logs be used?",
              options: [
                "Record temperatures of TCS foods at regular intervals to ensure compliance with safety standards."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-transportation",
          urlSlug: "transportation",
          name: "Transportation",
          header: "",
          questions: [
            {
              id: "outdoor-dining-transportation-transportation",
              reportWording:
                "Below are how food will be transported between outdoor preparation and the interior kitchen:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will food be transported between outdoor preparation and the interior kitchen",
              options: [
                "Covered Containers: Use sealed, food-grade containers with lids to prevent contamination during transport.",
                "Food-Safe Packaging: Transport food in food-safe, single-use packaging, such as plastic wraps, aluminum foil, or disposable containers",
                "Insulated Containers: Use insulated containers for maintaining proper temperature control during transport (e.g., hot foods in insulated hot-holding containers, cold foods in insulated coolers).",
                "Transport Carts: Utilize clean, covered transport carts to move food safely between areas while minimizing exposure to the environment"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-hand-washing",
          urlSlug: "hand-washing",
          name: "Hand Washing",
          header: "",
          questions: [
            {
              id: "outdoor-dining-hand-washing-outside-hand-washing",
              reportWording: "Below are how your employees will wash their hands outside:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will your employees wash their hands outside?",
              options: [
                "Portable Handwashing Stations: Portable sinks with running water, soap, and paper towels for proper handwashing.",
                "Gravity-Fed Water Containers: Use containers with spigots that allow water flow, paired with soap and paper towels, to create a handwashing setup where running water isn't available.",
                "Foot-Pump Handwashing Stations: Set up foot-pump-operated sinks that dispense water for handwashing, ensuring hands remain uncontaminated."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-protecting-utensils",
          urlSlug: "protecting-utensils",
          name: "Protecting Utensils",
          header: "",
          questions: [
            {
              id: "outdoor-dining-protecting-utensils-protecting-utensils",
              reportWording: "Below are how the utensils will be protected outdoors:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will your utensils be protected outdoors?",
              options: [
                "Covered Storage: Store utensils and equipment in covered, sealed containers or cabinets",
                "Sneeze Guards and Food Shields: Use sneeze guards or shields at food preparation stations",
                "Single-Use Items: Utilize single-use disposable utensils and food contact surfaces (e.g., plates, trays)",
                "Utensil Holders: Keep clean utensils in dedicated holders or pouches",
                "Staff Handling: Ensure staff use gloves or tongs when handling clean utensils and equipment"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-cleaning-utensils",
          urlSlug: "cleaning-utensils",
          name: "Cleaning Utensils",
          header: "",
          questions: [
            {
              id: "outdoor-dining-cleaning-utensils-cleaning-utensils",
              reportWording: "Below are your cleaning methods for utensils outdoors:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What are your cleaning methods for utensils outdoors?",
              options: [
                "Portable 3-Compartment Sink: Set up a portable 3-compartment sink system with separate basins for washing, rinsing, and sanitizing utensils and equipment.",
                "Sanitizing Solutions: Use food-safe sanitizing solutions (e.g., chlorine or quaternary ammonium) in spray bottles to sanitize utensils and food contact surfaces between uses.",
                "Disposable Wipes: Use pre-moistened, FDA-approved sanitizing wipes for quick cleaning and sanitizing of surfaces during operations.",
                "Regular Cleaning Schedule: Establish a regular cleaning schedule for outdoor equipment, utensils, and surfaces, especially between tasks or when switching between raw and ready-to-eat foods.",
                "Air-Drying: Allow washed and sanitized utensils to air dry in a clean area, rather than using towels that may carry bacteria."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-outdoor-foods",
          urlSlug: "outdoor-foods",
          name: "Outdoor Foods",
          header: "",
          questions: [
            {
              id: "outdoor-dining-outdoor-foods-served",
              reportWording: "Below are the foods that will be served outdoors:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What foods will be served outdoors?",
              dependencies: [
                { questionId: "restaurant-information-food-items-food", type: QuestionDependencyType.Required }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-outdoor-equipment",
          urlSlug: "outdoor-equipment",
          name: "Outdoor Equipment",
          header: "",
          questions: [
            {
              id: "outdoor-dining-outdoor-equipment-outdoor-equipment",
              reportWording: "Below are the equipment that will be utilized outdoors:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What equipment will be utilized outdoors?",
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-portability",
          urlSlug: "portability",
          name: "Portability",
          header: "",
          questions: [
            {
              id: "outdoor-dining-portability-equipment-portability",
              reportWording: "Below are how the equipment will be utilized outdoors:",
              prompt: "Is the equipment portable or permanently fixed?",
              required: true,
              footnote: "",
              excludeFromReport: false,
              options: ["Portable", "Permanent"],
              type: QuestionType.AccordionMultiSelect,
              dependencies: [
                {
                  questionId: "restaurant-information-equipment-review-equipment-review",
                  type: QuestionDependencyType.NotRequired
                }
              ]
            }
          ]
        },
        {
          id: "outdoor-dining-preparation-area",
          urlSlug: "preparation-area",
          name: "Preparation Area",
          header: "",
          questions: [
            {
              id: "outdoor-dining-preparation-area-preparation",
              reportWording: "Below is the location of the outdoor preparation area on the premises:",
              type: QuestionType.Textarea,
              prompt: "What is the location of the outdoor preparation area on the premises?",
              options: [],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-access",
          urlSlug: "access",
          name: "Access",
          header: "",
          questions: [
            {
              id: "outdoor-dining-access-access",
              reportWording: "Below are how unauthorized access to this area will be prevented:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will unauthorized access to this area be prevented?",
              options: ["Fencing or temporary barriers", "Gates and locks", "Signage", "Security"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-fuel-sources",
          urlSlug: "fuel-sources",
          name: "Fuel Sources",
          header: "",
          questions: [
            {
              id: "outdoor-dining-fuel-sources-fuel",
              reportWording: "Below are the fuel sources that will be used for outdoor cooking equipment:",
              type: QuestionType.MultiselectWithOther,
              prompt: "What fuel sources will be used for outdoor cooking equipment?",
              options: ["Wood", "Propane", "Charcol"],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-waste",
          urlSlug: "waste",
          name: "Waste",
          header: "",
          questions: [
            {
              id: "outdoor-dining-waste-waste",
              reportWording: "Below are how waste will be collected:",
              type: QuestionType.MultiselectWithOther,
              prompt: "How will waste be collected?",
              options: [
                "Trash bins with disposable liners",
                "Portable dumpster",
                "Designated waste receptacles for waste, recycling etc"
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-ash",
          urlSlug: "ash",
          name: "Ash",
          header: "",
          questions: [
            {
              id: "outdoor-dining-ash-ash",
              reportWording: "Below is how your ash will be collected (if applicable):",
              type: QuestionType.Textarea,
              prompt: "Describe the collection of your ash (if applicable)",
              options: [
                "Use heat-resistant, metal containers with lids to safely collect and store waste ash from grills, smokers, or fire pits.  Ensure the containers are placed on non-combustible surfaces away from food preparation areas to prevent fire hazards. Allow hot ash to cool completely before transferring it to designated disposal containers. Transport ash to a safe, approved disposal site or follow local waste management guidelines for ash disposal."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        },
        {
          id: "outdoor-dining-pests",
          urlSlug: "pests",
          name: "Pests",
          header: "",
          questions: [
            {
              id: "outdoor-dining-pests-pests",
              reportWording:
                "Below are the measures that will be taken to protect the outdoor cooking area from pest infestation (Including wood storage if applicable):",
              type: QuestionType.MultiselectWithOther,
              prompt:
                "Describe what measures will be taken to protect the outdoor cooking area from pest infestation (Including wood storage if applicable)",
              options: [
                "Store all food items in sealed, pest-proof containers, and avoid leaving any food exposed to the open air.",
                "Use refrigerators, coolers, or lockable cabinets for perishable items, and ensure they remain closed when not in use.",
                "Store cooking fuel like wood on elevated platforms or shelves, off the ground, to prevent direct contact with pests.",
                "Keep wood or other fuel covered with tarps or stored in tightly sealed containers to prevent pest access.",
                "Place natural pest repellents (e.g., cedar chips, essential oils) around the storage area to deter pests from nesting in the woodpile.",
                "Ensure that trash bins are equipped with tight-fitting lids and are emptied frequently to prevent pest attraction.",
                "Use separate bins for food waste and ash, keeping them securely covered at all times.",
                "Regularly clean food preparation areas, including cooking surfaces, utensils, and floors, to remove any food particles or spills that could attract pests.",
                "Establish a cleaning schedule to ensure the area is thoroughly cleaned after each meal service or shift.",
                "Use physical barriers such as screens, mesh, or tents with sidewalls to keep pests like insects and rodents out of the cooking area.",
                "Install air curtains or fans around entrances to deter flying insects.",
                "Remove any sources of standing water around the outdoor area, such as puddles or containers, to avoid attracting insects like mosquitoes and flies.",
                "Use yellow 'bug lights' or other non-attracting lighting solutions to reduce insect attraction during evening food preparation.",
                "Set up pest traps around the perimeter of the food preparation area for additional protection, ensuring they are placed far enough away from the cooking zones.",
                "If necessary, hire professional pest control services to implement long-term preventative measures and treatments around the outdoor cooking area."
              ],
              required: true,
              footnote: "",
              excludeFromReport: false
            }
          ]
        }
      ]
    }
  ]
};
