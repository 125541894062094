const isRunningInDevelopment = import.meta.env.DEV;
const testSiteUrl = import.meta.env.VITE_TEST_SITE_API_URL;

export const currentOrigin = typeof window !== "undefined" ? window.location.origin : "";

export const baseApiUrl = testSiteUrl || (isRunningInDevelopment ? "/api" : "");
export const baseSiteUrl = testSiteUrl || (isRunningInDevelopment ? import.meta.env.VITE_API_URL : "");

// TODO: We can eventually make this direct to the page the user was on before being redirected to the login page
const baseLoginUrl = `${baseSiteUrl}/v1/Account/Signin?redirectUrl=`;
export const loginUrl = isRunningInDevelopment ? `${baseLoginUrl}/v1/Home/RedirectToDevUrl` : `${baseLoginUrl}/`;

const baseLogoutUrl = `${baseSiteUrl}/v1/Account/SignOut?redirectUrl=`;
export const logoutUrl = isRunningInDevelopment ? `${baseLogoutUrl}/v1/Home/RedirectToDevUrl` : `${baseLogoutUrl}/`;

export const accessDeniedUrl = `${currentOrigin}/not-authorized`;
