import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { RestaurantInfo, restaurantService } from "../services/restaurant-service";
import { useAppStore } from "../store";
import { useErrorToast } from "./useErrorToast";

export const useRestaurantInfo = () => {
  const queryClient = useQueryClient();
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const { showError } = useErrorToast();

  const {
    data: restaurantInfo,
    isLoading: isLoadingRestaurantInfo,
    error: restaurantInfoError
  } = useQuery<RestaurantInfo | null, Error>({
    queryKey: ["restaurant"],
    queryFn: () => restaurantService.getRestaurantInfo(),
    enabled: !!userInfo?.id,
    retry: false
  });

  // Show error toast when the query fails
  if (restaurantInfoError) {
    showError(restaurantInfoError, {
      title: "Failed to Load Restaurant Info",
      description: "There was an error loading your restaurant information. Please try refreshing the page."
    });
  }

  const { mutateAsync: saveRestaurantInfo, isPending: isSavingRestaurantInfo } = useMutation({
    mutationFn: ({ name, culture }: { name: string; culture: string }) =>
      restaurantService.saveRestaurantInfo(name, culture),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["restaurantInfo"] });
      return restaurantInfo;
    },
    onError: (error) => {
      showError(error, {
        title: "Failed to Save Restaurant Info",
        description: "There was an error saving your restaurant information. Please try again."
      });
    }
  });

  return {
    restaurantInfo,
    isLoadingRestaurantInfo,
    restaurantInfoError,
    saveRestaurantInfo,
    isSavingRestaurantInfo
  };
};
