import { useEffect, useState } from "react";

import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView } from "@blocknote/mantine";
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  CreateLinkButton,
  FormattingToolbar,
  FormattingToolbarController,
  NestBlockButton,
  UnnestBlockButton,
  useCreateBlockNote
} from "@blocknote/react";
import {
  VStack,
  Heading,
  Button,
  Box,
  Text,
  Divider,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  ButtonGroup,
  Spinner,
  IconButton,
  Icon
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { MdContentCopy } from "react-icons/md";

import { useJobDescription } from "../../hooks/useJobDescription";
import { useRestaurantInfo } from "../../hooks/useRestaurantInfo";
import { jobDescriptionService } from "../../services/job-description-service";

import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";

interface JobDescriptionReviewProps {
  jobDescriptionId: number;
  onStartFresh: () => void;
}

export const JobDescriptionReview = ({ jobDescriptionId, onStartFresh }: JobDescriptionReviewProps) => {
  const editor = useCreateBlockNote({ trailingBlock: false });
  const [isDirty, setIsDirty] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { restaurantInfo } = useRestaurantInfo();
  const { saveGeneratedJobDescription, isSavingGeneratedJobDescription } = useJobDescription(jobDescriptionId);

  const { data: jobDescriptionResult, isLoading } = useQuery({
    queryKey: ["jobDescriptionResult", jobDescriptionId],
    queryFn: () => jobDescriptionService.getGeneratedJobDescription(jobDescriptionId)
  });

  useEffect(() => {
    const loadJobDescription = async (editor: BlockNoteEditor, content: string) => {
      const markdownBlocks = await editor.tryParseMarkdownToBlocks(content);
      editor.replaceBlocks(editor.document, markdownBlocks);
      setIsDirty(false);
    };

    if (jobDescriptionResult?.markdownContent) {
      loadJobDescription(editor, jobDescriptionResult.markdownContent);
    }
  }, [jobDescriptionResult, editor]);

  if (isLoading) {
    return (
      <VStack spacing={4} pt={8}>
        <Spinner size="xl" />
        <Text>Loading job description...</Text>
      </VStack>
    );
  }

  if (!jobDescriptionResult) {
    return (
      <VStack spacing={4} pt={8}>
        <Text>Error loading job description. Please try again.</Text>
        <Button onClick={onStartFresh}>Start Fresh</Button>
      </VStack>
    );
  }

  const handleSaveJobDescription = async () => {
    if (!restaurantInfo) {
      throw new Error("Restaurant info is not available");
    }

    try {
      const blocks = editor.document;
      const markdown = await editor.blocksToMarkdownLossy(blocks);

      const result = await saveGeneratedJobDescription({
        id: jobDescriptionResult.id || null,
        restaurantId: restaurantInfo.id,
        jobDescriptionInfoId: jobDescriptionId,
        markdownContent: markdown
      });

      if (result) {
        toast({
          title: "Job Description Saved",
          description: "Your job description has been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top"
        });
        setIsDirty(false);
      } else {
        throw new Error("Failed to save job description");
      }
    } catch (error) {
      console.error("Error saving job description:", error);
      toast({
        title: "Error",
        description: "There was an error saving your job description. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
  };

  const handleStartFresh = () => {
    if (isDirty) {
      onOpen();
    } else {
      onStartFresh();
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      const htmlContent = await editor.blocksToFullHTML(editor.document);
      const blob = new Blob([htmlContent], { type: "text/html" });
      const item = new ClipboardItem({ "text/html": blob });
      await navigator.clipboard.write([item]);
    } catch (error) {
      alert("Failed to copy content.");
    }
  };

  const handleCreateAnother = () => {
    onClose();
    onStartFresh();
  };

  return (
    <VStack borderWidth={1} borderRadius="lg" p={4} spacing={4} width="100%">
      <Box px="54px" width="100%">
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="100%" mb={4}>
          <Heading size="lg" my={4} textAlign="center">
            {`${jobDescriptionResult.jobDescriptionInfo.position} Job Description`}
          </Heading>
          <ButtonGroup position="absolute" right={0}>
            <Button
              colorScheme="brand"
              onClick={handleSaveJobDescription}
              isLoading={isSavingGeneratedJobDescription}
              isDisabled={!restaurantInfo || !isDirty}
            >
              {isDirty ? "Save" : "Saved"}
            </Button>
            <Button onClick={handleStartFresh}>Start Fresh</Button>
            <IconButton onClick={handleCopyToClipboard} aria-label="copy to clipboard">
              <Icon as={MdContentCopy} />
            </IconButton>
          </ButtonGroup>
        </Box>
        <Text fontStyle="italic">You can edit your job description below. Don't forget to save your changes!</Text>
      </Box>
      <Divider />
      <BlockNoteView
        editor={editor}
        theme="light"
        sideMenu={false}
        linkToolbar={false}
        filePanel={false}
        slashMenu={false}
        tableHandles={false}
        formattingToolbar={false}
        onChange={() => setIsDirty(true)}
      >
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              <BlockTypeSelect key="blockTypeSelect" />
              <BasicTextStyleButton basicTextStyle="bold" key="boldStyleButton" />
              <BasicTextStyleButton basicTextStyle="italic" key="italicStyleButton" />
              <NestBlockButton key={"nestBlockButton"} />
              <UnnestBlockButton key={"unnestBlockButton"} />
              <CreateLinkButton key={"createLinkButton"} />
            </FormattingToolbar>
          )}
        />
      </BlockNoteView>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please make sure you have saved your current job description before generating a new one.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleCreateAnother}>
              Yes, I'm sure
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
