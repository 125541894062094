import { FormControl, FormLabel, Heading, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

import { HandbookFormData } from "../types";

interface SectionProps {
  control: Control<HandbookFormData>;
}

export const PrivacySection = ({ control }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Privacy Policies</Heading>

      {/* Electronic Signature Policy */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be requiring employees to use electronic signatures?
          </Heading>
        </FormLabel>
        <Controller
          name="uses-electronic-signatures"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {/* Personal Files and SSN Management */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be posting pictures of your staff or guests for marketing purposes?
          </Heading>
        </FormLabel>
        <Controller
          name="posts-marketing-photos"
          control={control}
          render={({ field }) => (
            <RadioGroup onChange={field.onChange} value={field.value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
    </>
  );
};
