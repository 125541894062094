import { FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";

interface SectionProps {
  control: Control<HandbookFormData>;
}

export const CompanyIntroSection = ({ control }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Company Introduction</Heading>
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your restaurant's culture, mission or core value(s)?
          </Heading>
        </FormLabel>
        <Text fontSize="sm" color="blue.400" mb={4}>
          This will help set the tone for your employee handbook.
        </Text>
        <Controller
          name="company-culture-q"
          control={control}
          render={({ field }) => (
            <TextInput {...field} value={field.value || ""} placeholder="Describe your restaurant's culture..." />
          )}
        />
      </FormControl>
    </>
  );
};
