import { ReactNode, useState } from "react";

import { Box, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface BodyProps {
  bodyPadding?: number[];
  bgColor?: string;
  children: ReactNode;
}

export default function Body({ children, bodyPadding, bgColor }: BodyProps) {
  const [showClipBoard, setShowClipBoard] = useState(false);

  return (
    <Box height="100%" w="100%" bgColor={[bgColor || "#F9F8F8", "#F9F8F8"]} p={[2, 6]} pb={0}>
      <Box h={"100%"} minH={"calc(100vh - 166px)"} borderRadius={25} p={bodyPadding} pb={0} bgColor={"white"}>
        {children}
      </Box>
      <motion.div
        initial={false}
        animate={{ right: showClipBoard ? 10 : -35 }}
        style={{ position: "fixed", bottom: 10, display: "none" }}
      >
        <Image
          src="/images/clipboard-gameplan.png"
          alt="hero"
          w="50px"
          h="auto"
          mt={6}
          cursor={"pointer"}
          onClick={() => setShowClipBoard((val) => !val)}
        />
      </motion.div>
    </Box>
  );
}
