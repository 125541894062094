import { useEffect, useState } from "react";

import { useMediaQuery } from "@chakra-ui/react";
import {
  Box,
  Divider,
  HStack,
  VStack,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button } from "@chakra-ui/react";
import { SignedIn, useUser } from "@clerk/clerk-react";
import { useClerk } from "@clerk/clerk-react";
import { motion } from "framer-motion";
import { CgProfile } from "react-icons/cg";
import { GoSidebarExpand, GoSidebarCollapse } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MenuLink, SideMenuLinks, TopMenuLinks, UserRole } from "./navLinks";
import { BrowserService } from "../services/browser-service";
import { useAppStore } from "../store";
import { ThemeColors } from "../styles/theme";

interface NavLinkMobileProps {
  item: MenuLink;
}

const NavLinkMobile = ({ item }: NavLinkMobileProps) => {
  return (
    <Link to={item.path} target={item.target}>
      <Box
        px={3}
        py={3}
        ml={3}
        display={"inline-block"}
        fontSize={"sm"}
        rounded={"md"}
        color={"black"}
        _hover={{
          textDecoration: "none",
          color: "black",
          bg: "gray.200"
        }}
      >
        {item.label}
      </Box>
    </Link>
  );
};

interface SideBarProps {
  menuIsVisible: boolean;
  closeMenu: () => void;
}

export default function SideBar({ menuIsVisible, closeMenu }: SideBarProps) {
  const clerk = useClerk();
  const { getButtonProps, isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [smallerThanLarge] = useMediaQuery("(max-width: 1500px)", { fallback: undefined });
  const [allowMobileClicks, setAllowMobileClicks] = useState(false);
  const [linksForUser, setLinksForUser] = useState<MenuLink[]>([]);
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const user = useUser();
  const navigate = useNavigate();

  const sidebarBgColor = "#F9F8F8";

  async function handleSignOut() {
    localStorage.removeItem("conversationFormValues");
    await clerk.signOut(() => {
      window.location.href = "/signin";
    });
  }

  const isCurrentPath = (path: string) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(path);
  };

  const location = useLocation();

  useEffect(() => {
    if (userInfo?.isAdmin) {
      setLinksForUser(SideMenuLinks);
    } else {
      setLinksForUser(SideMenuLinks.filter((link) => link.userRoles.includes(UserRole.User)));
    }
  }, [userInfo]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (menuIsVisible) {
      intervalId = setTimeout(() => {
        setAllowMobileClicks(true);
      }, 500);
    } else {
      setAllowMobileClicks(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setAllowMobileClicks(false);
      }
    };
  }, [menuIsVisible]);

  useEffect(() => {
    if (smallerThanLarge !== undefined && smallerThanLarge) {
      onClose();
    }
  }, [smallerThanLarge, onClose, location]);

  function handleOnMouseOver(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  function handleOnMouseOut(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  return (
    <Box height="100%">
      {/* For mobile view only */}
      {/* TODO: Clean this up to remove duplicate code */}
      <Drawer isOpen={menuIsVisible} placement="left" onClose={() => closeMenu()} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent bg={sidebarBgColor} p={0} pt={14}>
          <DrawerCloseButton color="black" _focusVisible={{ boxShadow: "" }} />

          <DrawerBody p={0} pointerEvents={allowMobileClicks ? "all" : "none"}>
            <Box pt={5} color={"white"}>
              <VStack as={"nav"} spacing={1} align={"left"} justify={"flex-end"} px=".5rem">
                {TopMenuLinks.map((link) => (
                  <NavLinkMobile key={link.label} item={link} />
                ))}
              </VStack>
              <VStack align={"left"} pt={3} pl={0} gap={0}>
                <Divider borderColor={"gray.500"} />
                {linksForUser.map((link) => (
                  <Link to={link.path} key={link.path}>
                    <HStack
                      justify={"flex-start"}
                      cursor={"pointer"}
                      py={2.5}
                      pl={4}
                      color={ThemeColors.brand.primary}
                      bgColor={isCurrentPath(link.path) ? "brand.primary" : "inherit"}
                      // _hover={{ bgColor: "purple.500" }}
                      position={"relative"}
                    >
                      <Box
                        pr={2}
                        fontSize={"1.6rem"}
                        position={"relative"}
                        top={"5px"}
                        left={1}
                        color={isCurrentPath(link.path) ? "white" : "brand.primary"}
                      >
                        {link.icon}
                      </Box>
                      <Text fontSize="sm" align={"center"} color={isCurrentPath(link.path) ? "white" : "black"}>
                        {link.label}
                      </Text>
                    </HStack>
                    <Divider borderColor={"gray.500"} />
                  </Link>
                ))}
              </VStack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* For desktop view only */}
      <Box
        hideBelow={"md"}
        position={"relative"}
        bg={sidebarBgColor}
        height="100%"
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        <motion.div
          hidden={false}
          initial={false}
          animate={{ width: isOpen ? 200 : 80 }}
          style={{
            overflow: "hidden",
            backgroundColor: sidebarBgColor,
            height: "100%",
            paddingLeft: "16px",
            paddingRight: "16px"
          }}
        >
          <Box h="full">
            <VStack align="left" pt={6} gap={2} h="full" pb={8}>
              <Box
                as="button"
                p={2}
                pl={isOpen ? 4 : 3}
                onClick={getButtonProps().onClick}
                _hover={{ bg: "gray.50" }}
                display="flex"
                alignSelf="flex-start"
              >
                {isOpen ? (
                  <GoSidebarExpand color="brand.primary" size={24} />
                ) : (
                  <GoSidebarCollapse color="brand.primary" size={24} />
                )}
              </Box>

              {linksForUser.map((link) => (
                <Link to={link.path} key={link.label} style={{ textDecoration: "none" }}>
                  <Box
                    as="button"
                    display="flex"
                    alignItems="center"
                    justifyContent={isOpen ? "flex-start" : "center"}
                    width={isOpen ? "full" : "48px"}
                    p={3}
                    px={isOpen ? 4 : 2}
                    borderRadius={8}
                    border="1px solid"
                    borderColor={isCurrentPath(link.path) ? "brand.primary" : "gray.300"}
                    bg={isCurrentPath(link.path) ? "brand.primary" : "transparent"}
                    _hover={{
                      bg: isCurrentPath(link.path) ? "brand.primary" : "gray.100"
                    }}
                    transition="all 0.2s"
                  >
                    <Box
                      color={isCurrentPath(link.path) ? "black" : "gray.600"}
                      fontSize="20px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      minWidth="30px"
                    >
                      {link.icon}
                    </Box>
                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Text ml={3} fontSize="sm" color={isCurrentPath(link.path) ? "black" : "gray.600"} fontWeight={isCurrentPath(link.path) ? "bold" : "normal"}>
                          {link.label}
                        </Text>
                      </motion.div>
                    )}
                  </Box>
                </Link>
              ))}

              {/* Add account menu at the bottom */}
              <Box marginTop="auto" pb={4} justifySelf="flex-end" zIndex={1000}>
                <SignedIn>
                  <Menu placement="right-start">
                    <MenuButton
                      as={Button}
                      variant="ghost"
                      cursor="pointer"
                      minW={0}
                      aria-label="User Menu"
                      p={2}
                      pl={3}
                      _hover={{ bg: "gray.100" }}
                    >
                      <HStack spacing={2}>
                        <Box color="brand.primary" fontSize="1.5rem" minWidth="30px">
                          <CgProfile />
                        </Box>
                        {isOpen && (
                          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
                            <Text color="brand.primary" fontSize="sm">
                              {user?.user?.firstName}
                            </Text>
                          </motion.div>
                        )}
                      </HStack>
                    </MenuButton>

                    <MenuList minW="0" borderColor="gray.300">
                      <MenuItem onClick={() => navigate("/account")} textAlign="center" px={6}>
                        Account
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={handleSignOut} px={6}>
                        Sign out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </SignedIn>
              </Box>
            </VStack>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
}
