/* eslint-disable @typescript-eslint/no-explicit-any */

import { UnAuthorizedResponse } from "@shared/models";
import axios, { AxiosInstance } from "axios";

import { accessDeniedUrl, baseApiUrl, loginUrl } from "../constants/constants";

// TODO: We can clean this up a bit
const axiosTransformers = [
  ...(axios.defaults.transformResponse as ((data: any) => any)[]),
  (data: any) => {
    const isDateProperty = (key: string, value: any): boolean =>
      typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value);

    const convertDates = (obj: any) => {
      if (obj === null || typeof obj !== "object") return obj;

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (isDateProperty(key, obj[key])) {
            obj[key] = new Date(obj[key]);
          } else if (typeof obj[key] === "object") {
            convertDates(obj[key]);
          }
        }
      }
      return obj;
    };

    return convertDates(data);
  }
];

export const axiosClient = axios.create({
  baseURL: baseApiUrl,
  timeout: 30000, // 30 seconds timeout
  headers: {
    "Content-Type": "application/json"
  },
  transformResponse: axiosTransformers
});

addInterceptors(axiosClient);

export const extendedTimeoutAxiosClient = axios.create({
  baseURL: baseApiUrl,
  timeout: 300000, // 5 minutes timeout
  headers: {
    "Content-Type": "application/json"
  },
  transformResponse: axiosTransformers
});

addInterceptors(extendedTimeoutAxiosClient);

function addInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    const separator = config.url?.includes("?") ? "&" : "?";
    config.url = `${config.url}${separator}isXHR=true`;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data?.isUnAuthorized) {
        console.log("Unauthorized", response.data);
        const unAuthResponse: UnAuthorizedResponse = response.data;
        if (unAuthResponse.redirectToSignIn) {
          window.location.href = loginUrl;
        } else if (unAuthResponse.redirectToAccessDenied) {
          window.location.href = accessDeniedUrl;
        } else {
          throw new Error("Unauthorized, but unexpected type");
        }

        return response;
      }
      return response;
    },
    (error) => {
      // If there's an error, we can handle it here
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response error:", error.response.status, error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
      // You can add more specific error handling here if needed
      // For example, redirecting to a login page on 401 errors

      // Reject the promise with the error so it can be caught by the calling code
      return Promise.reject(error);
    }
  );
}
