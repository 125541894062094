"use client";

import { ReactNode, useEffect } from "react";

import { Grid, GridItem, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import Body from "./Body";
import Footer from "./Footer";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

type LayoutProps = {
  bodyPadding?: number[];
  bgColor?: string;
  children: ReactNode;
};

export default function Layout({ children, bodyPadding, bgColor }: LayoutProps) {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const location = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const bodyPaddingToUse = bodyPadding === undefined ? [2, 6] : bodyPadding;

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <Grid
      className="id--layout"
      templateAreas={isMobile ? `"nav" "main" "footer"` : `"nav main" "nav footer"`}
      gridTemplateRows={isMobile ? `auto 1fr auto` : `1fr auto`}
      gridTemplateColumns={isMobile ? `1fr` : `auto 1fr`}
      height="100vh"
    >
      {isMobile && (
        <GridItem area="nav">
          <NavBar menuIsVisible={isOpen} openMenu={onOpen} />
        </GridItem>
      )}
      <GridItem area="main" as="main">
        <Body children={children} bodyPadding={bodyPaddingToUse} bgColor={bgColor} />
      </GridItem>
      {!isMobile && (
        <GridItem area="nav" as="aside">
          <SideBar menuIsVisible={isOpen} closeMenu={onClose} />
        </GridItem>
      )}
      {isMobile && <SideBar menuIsVisible={isOpen} closeMenu={onClose} />}
      <GridItem area="footer" as="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
}
