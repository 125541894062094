import { useState } from "react";

import { useToast } from "@chakra-ui/react";
import {
  JobFormData,
  JobDescriptionSection,
  JOB_DESCRIPTION_SECTIONS,
  SaveGeneratedJobDescriptionRequest
} from "@shared/job-description-models";
import { useMutation } from "@tanstack/react-query";

import { useJobDescription } from "./useJobDescription";
import { generateJobDescriptionSection } from "../services/api-service";

export const useJobDescriptionGenAI = () => {
  const [isGeneratingJobDescription, setIsGeneratingJobDescription] = useState(false);
  const toast = useToast();
  const { saveGeneratedJobDescription } = useJobDescription();

  const generateSectionMutation = useMutation({
    mutationFn: ({ section, fullContext }: { section: JobDescriptionSection; fullContext: JobFormData }) =>
      generateJobDescriptionSection(section, fullContext)
  });

  const generateSection = async (section: JobDescriptionSection, fullContext: JobFormData): Promise<string> => {
    if (!section.content) {
      return "";
    }

    const result = await generateSectionMutation.mutateAsync({ section, fullContext });
    return result;
  };

  const generateJobDescription = async (formData: JobFormData, jobDescriptionId: number, restaurantId: number) => {
    setIsGeneratingJobDescription(true);
    try {
      const sections: JobDescriptionSection[] = [
        {
          title: JOB_DESCRIPTION_SECTIONS.COMPANY_OVERVIEW,
          content: formData.culture
        },
        {
          title: JOB_DESCRIPTION_SECTIONS.RESPONSIBILITIES,
          content: formData.responsibilities.join("\n")
        },
        {
          title: JOB_DESCRIPTION_SECTIONS.REQUIRED_QUALIFICATIONS,
          content: formData.requiredQualifications.join("\n")
        },
        {
          title: JOB_DESCRIPTION_SECTIONS.PREFERRED_QUALIFICATIONS,
          content: formData.preferredQualifications.join("\n")
        },
        {
          title: JOB_DESCRIPTION_SECTIONS.BENEFITS,
          content: formData.benefits.join("\n")
        },
        {
          title: JOB_DESCRIPTION_SECTIONS.HOW_TO_APPLY,
          content:
            formData.howToApply +
            (formData.additionalInfo ? "\n\nAdditional Information:\n" + formData.additionalInfo : "")
        }
      ];

      const sectionPromises = sections.map((section) => generateSection(section, formData));
      const sectionResults = await Promise.all(sectionPromises);
      const fullDescription = [...sectionResults].join("\n\n");

      // Save the result immediately
      const saveRequest: SaveGeneratedJobDescriptionRequest = {
        id: null,
        restaurantId,
        jobDescriptionInfoId: jobDescriptionId,
        markdownContent: fullDescription
      };
      await saveGeneratedJobDescription(saveRequest);

      return jobDescriptionId;
    } catch (error) {
      console.error("Error generating job description:", error);
      toast({
        title: "Generation Failed",
        position: "top",
        description:
          "The Job Description failed to generate, this is likely due to the generative AI system being down momentarily. Please try again later or reach out if this has been an ongoing issue.",
        status: "error",
        duration: 8000,
        isClosable: true
      });
      throw error;
    } finally {
      setIsGeneratingJobDescription(false);
    }
  };

  return {
    generateJobDescription,
    isGeneratingJobDescription
  };
};
