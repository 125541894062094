export interface JobFormData {
  name: string;
  position: string[];
  culture: string;
  responsibilities: string[];
  requiredQualifications: string[];
  preferredQualifications: string[];
  benefits: string[];
  howToApply: string;
  additionalInfo: string;
}

export interface JobDescriptionSection {
  title: string;
  content: string;
}

export interface JobDescriptionInfo {
  id: number;
  userId: number;
  restaurantId: number;
  position: string;
  responsibilities: string[];
  requiredQualifications: string[];
  preferredQualifications: string[];
  benefits: string[];
  howToApply: string;
  additionalInfo?: string;
  createdAt: string;
}

export interface GeneratedJobDescription {
  id: number;
  userId: number;
  restaurantId: number;
  jobDescriptionInfoId: number;
  markdownContent: string;
  createdAt: string;
}

export interface GeneratedJobDescriptionWithInfo extends GeneratedJobDescription {
  jobDescriptionInfo: JobDescriptionInfo;
}

// Request types for the service
export interface CreateJobDescriptionRequest {
  restaurantId: number;
  position: string;
  responsibilities: string[];
  requiredQualifications: string[];
  preferredQualifications: string[];
  benefits: string[];
  howToApply: string;
  additionalInfo?: string;
}

export interface SaveGeneratedJobDescriptionRequest {
  id: number | null;
  restaurantId: number;
  jobDescriptionInfoId: number;
  markdownContent: string;
}

// Default options for form fields
export const DEFAULT_POSITIONS = [
  "Server",
  "Bartender",
  "Cook",
  "Manager",
  "Host",
  "Busser",
  "Dishwasher",
  "Delivery Driver"
];

export const DEFAULT_RESPONSIBILITIES = [
  "Customer Service",
  "Order taking",
  "Menu knowledge",
  "Cleanliness and sanitation",
  "Food and beverage service",
  "Experience enhancing sales",
  "Team collaboration",
  "Payment processing"
];

export const DEFAULT_REQUIRED_QUALIFICATIONS = [
  "Guest-focused attitude",
  "Ability to work in a fast-paced environment",
  "Excellent communication skills",
  "Teamwork",
  "Flexibility",
  "Problem-solving skills",
  "Ability to lift over 25 pounds"
];

export const DEFAULT_PREFERRED_QUALIFICATIONS = [
  "Prior serving experience",
  "Alcoholic beverage knowledge",
  "Experience with wine service"
];

export const DEFAULT_BENEFITS = [
  "Employee discounts",
  "Growth opportunities",
  "Flexible scheduling",
  "Health insurance opportunities",
  "Paid time off",
  "401K"
];

// Section titles for job description generation
export const JOB_DESCRIPTION_SECTIONS = {
  COMPANY_OVERVIEW: "Company Overview",
  RESPONSIBILITIES: "Job Responsibilities",
  REQUIRED_QUALIFICATIONS: "Required Qualifications",
  PREFERRED_QUALIFICATIONS: "Preferred Qualifications",
  BENEFITS: "Benefits",
  HOW_TO_APPLY: "How to Apply"
} as const;
