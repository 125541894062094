import { UserSopAnswer } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { useErrorToast } from "./useErrorToast";
import { getSopAnswers } from "../services/api-service";

export const useSopAnswers = () => {
  const { showError } = useErrorToast();

  const { data, isLoading, error } = useQuery<UserSopAnswer[], Error>({
    queryKey: ["sopAnswers"],
    queryFn: getSopAnswers,
    retry: false
  });

  if (error) {
    showError(error, {
      title: "Failed to Load SOP Answers",
      description: "There was an error loading your SOP answers. Please try refreshing the page."
    });
  }

  return { data, isLoading, error };
};
