
import { VStack, Heading, Text, HStack } from "@chakra-ui/react";
import { QuestionType, Question, QuestionDependencyType } from "@shared/models";

import CustomQuestions from "./CustomQuestions";
import DependencyInfoPopover from "./DependencyInfoPopover";
import { workflow } from "../../constants/workflow";
import { useDependencyState } from "../../hooks/useDependencyState";
import { Session } from "../../store";
import Context from "../Context";
import ListMultiSelect from "../MultiSelect/ListMultiSelect";
import OtherMultiSelect from "../MultiSelect/OtherMultiSelect";
import TableMultiselect from "../TableMultiSelect/TableMultiSelect";
import TextInput from "../TextInput";
import { YesNoSelection } from "../YesNoSelection";

interface DynamicQuestionsProps {
  question: Question;
  handleNextClick: () => void;
  session: Session;
  onAnswerChange: (questionId: string, answer: string | boolean | object) => void;
}

const DynamicQuestion: React.FC<DynamicQuestionsProps> = ({ question, handleNextClick, session, onAnswerChange }) => {
  const { id, type, prompt, dependencies, options, footnote } = question;

  const {
    // unansweredDependencies,
    unansweredRequiredDependencies,
    hasUnansweredDependencies,
    hasUnansweredRequiredDependencies,
    dependencyAnswers,
    inputValue,
    setInputValue
  } = useDependencyState(question, session, onAnswerChange);

  if (hasUnansweredDependencies) {
    if (hasUnansweredRequiredDependencies) {
      return (
        <VStack alignItems="flex-start" justifyContent="flex-start" w="full" gap={4}>
          <Text color="red.500" fontWeight="bold">
            The following questions must be answered first:
          </Text>
          {unansweredRequiredDependencies.map((dep) => {
            const section = workflow.sections.find((section) =>
              section.pages.find((page) => page.questions.find((q) => q.id === dep.questionId))
            );
            const page = section?.pages.find((page) => page.questions.find((q) => q.id === dep.questionId));
            const dependentQuestion = page?.questions.find((q) => q.id === dep.questionId);

            return (
              <Text key={dep.questionId} color="red.500" ml={4} w="full" flexWrap="wrap">
                <VStack alignItems="flex-start" justifyContent="flex-start" w="full" gap={1} as="span">
                  <HStack as="span" width="full" flexWrap="wrap">
                    <Text as="span">Section: {section?.name || "Unknown section"}</Text>
                    <Text as="span">Page: {page?.name || "Unknown page"}</Text>
                  </HStack>
                  <Text as="span" fontWeight="bold" ml={4}>
                    Question: {dependentQuestion?.prompt || "Unknown question"}
                  </Text>
                </VStack>
              </Text>
            );
          })}
        </VStack>
      );
    } else {
      // If there are only silent dependencies, don't render anything
      return null;
    }
  }

  return (
    <VStack key={prompt} alignItems="flex-start" justifyContent="flex-start" w="full" gap={4}>
      <Heading size="md">{prompt}</Heading>

      {footnote && type !== QuestionType.FileUpload && <Text fontSize="sm" color="gray.500" alignSelf="flex-start" fontStyle="italic">{footnote}</Text>}

      {dependencies?.some(
        (dep) => dep.type === QuestionDependencyType.Required || dep.type === QuestionDependencyType.NotRequired
      ) && <DependencyInfoPopover dependencies={dependencies} />}

      {type === QuestionType.Textarea && options && options.length > 0 && (
        <Text fontSize="sm" color="gray.500" alignSelf="flex-start" fontStyle="italic" minH={6}>
          {inputValue === options[0]
            ? "The answer below is prefilled to get you started. You can update it as necessary."
            : ""}
        </Text>
      )}

      {type === QuestionType.TextareaFromDependencies && dependencyAnswers && (
        <Text fontSize="sm" color="gray.500" alignSelf="flex-start" fontStyle="italic" minH={6}>
          {inputValue === dependencyAnswers[0]
            ? "The answer below is prefilled from the dependent question. You can update the answer below as necessary to update this question only."
            : ""}
        </Text>
      )}

      {type === QuestionType.SingleSelect && (
        <ListMultiSelect
          selectedItems={session[id]?.answer ? [session[id]?.answer as string] : []}
          defaultItems={options || []}
          onButtonClick={(value: string) => onAnswerChange(id, value)}
          maintainOrder
        />
      )}

      {type === QuestionType.Multiselect && (
        <ListMultiSelect
          selectedItems={session[id]?.answer as string[]}
          defaultItems={options || dependencyAnswers || []}
          onButtonClick={(value: string) => onAnswerChange(id, value)}
          maintainOrder
        />
      )}

      {(type === QuestionType.MultiselectWithOther || type === QuestionType.MultiselectPreselectedFromDependencies) && (
        <OtherMultiSelect
          selectedItems={session[id]?.answer as string[]}
          defaultItems={options || [...dependencyAnswers, "N/A"] || []}
          onButtonClick={(value: string) => onAnswerChange(id, value)}
          onAddClick={(value: string) => onAnswerChange(id, value)}
        />
      )}

      {type === QuestionType.YesNo && (
        <YesNoSelection
          selected={session[id]?.answer as boolean}
          onAffirmativeClick={() => onAnswerChange(id, true)}
          onNegativeClick={() => onAnswerChange(id, false)}
        />
      )}

      {(type === QuestionType.Textarea || type === QuestionType.TextareaFromDependencies) && (
        <TextInput
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          onBlur={() => onAnswerChange(id, inputValue)}
          placeholder="Type anything..."
        />
      )}

      {type === QuestionType.TableSelect && (
        <TableMultiselect question={question} session={session} onSelect={(value) => onAnswerChange(id, value)} />
      )}

      {(type === QuestionType.Custom ||
        type === QuestionType.BasicInfo ||
        type === QuestionType.AccordionMultiSelect ||
        type === QuestionType.AccordionMultiSelectDepsAsOptions ||
        type === QuestionType.FileUpload) && (
          <CustomQuestions
            question={question}
            session={session}
            dependencyAnswers={dependencyAnswers}
            onAnswerChange={onAnswerChange}
            handleNextClick={handleNextClick}
          />
        )}

      {type === QuestionType.ConfirmContext && (
        <Context
          answer={session[id]?.answer as boolean}
          contentFile={question.contentFile || ""}
          question={prompt || ""}
          onAffirmativeClick={() => {
            onAnswerChange(id, true);
            handleNextClick();
          }}
        />
      )}

      {type !== QuestionType.ConfirmContext && question.contentFile && (
        <Context
          answer={session[id]?.answer as boolean}
          contentFile={question.contentFile || ""}
          question={prompt || ""}
          onAffirmativeClick={() => { }}
          showButton={false}
        />
      )}

      {/* AFTER BETA TESTING: Show only for admins. For testing, good for gathering feedback. */}
      <Text fontSize="sm" color="gray.500" alignSelf="flex-end" fontStyle="italic">
        id: {id}
      </Text>
    </VStack>
  );
};

export default DynamicQuestion;
