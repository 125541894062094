export const PAYMENT_METHOD_OPTIONS = [
  "Direct Deposit Only",
  "Paper Check Only",
  "Direct Deposit or Paper Check",
  "Other"
];

export const PTO_ROLLOVER_OPTIONS = [
  "PTO does not rollover unless approved by manager",
  "PTO will roll over one calendar year",
  "PTO will roll over two calendar years",
  "Other"
];

export const HEALTH_INSURANCE_OPTIONS = [
  "We will be offering insurance to employees after their first 90 days if the average FTE hours.",
  "We will provide insurance annually during open enrollment to those who average FTE hours over the last 12 months.",
  "We will not be offering health insurance (in Michigan, you do not have to offer insurance if you have less than 50 employees, but you must provide information on the Marketplace)."
];

export const DRUG_TEST_OPTIONS = [
  "Pre-employment",
  "Random",
  "Post Accident",
  "Reasonable Suspicion",
  "Return to work"
];

export const TERMINATION_GROUNDS_OPTIONS = [
  "Falsification of personal records or company documents",
  "Violence or weapons in the workplace",
  "Bullying in-person or digitally",
  "Absence without notification for three consecutive days",
  "Creating an unsafe work environment by any means, whether it be physically, mentally, emotionally, financially",
  "Unauthorized use of company resources",
  "Theft",
  "Insubordination",
  "Excessive absences or tardiness",
  "Violation of the harassment policy outlined in this handbook",
  "Any infraction outlined in this handbook",
  "Infractions of the drug and alcohol policy outlined in this handbook"
];

export const TRADE_SECRET_OPTIONS = [
  "Employees must not disclose proprietary or confidential information without explicit approval from management. This includes any sensitive or trade secret information. Unauthorized disclosure, whether during or after employment, may result in disciplinary action, including termination, and potential legal proceedings.",
  "Employees are also prohibited from sharing the organization's confidential information on public or social media platforms. This includes trade secrets, client lists, or data restricted by law. While discussing job-related experiences generally is permitted, revealing confidential or proprietary details is not. For guidance on confidential information, consult the organization's confidentiality policy. Employees should seek clarification before sharing any potentially sensitive content.",
  "Other"
];

export const MEDIA_INQUIRY_OPTIONS = [
  "Media inquiries should be handled by the manager on duty",
  "Media inquiries should be routed to the owner",
  "We won't handle media inquiries",
  "Other"
];

export const INCIDENT_REPORTING_OPTIONS = [
  "If you experience or witness any workplace injury, illness, or accident, immediately report it to your Manager, the manager-on-duty, or Human Resources, including any damage or misuse of company property. Ensure timely reporting for Workers' Compensation claims and to enable management to prevent similar future incidents. Document all incidents through an Incident Report and submit to Human Resources and relevant managers within 24 hours.",
  "Employees must provide detailed information for investigations and may be held financially responsible for property damage due to negligence or unauthorized actions. There will be no retaliation for reporting workplace incidents, and employees are encouraged to report unsafe behaviors and suggest safety improvements to enhance workplace safety.",
  "Other"
];
