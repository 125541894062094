// Basic markdown elements
export const h1 = (text: string) => `# ${text}\n\n\n`;
export const h2 = (text: string) => `## ${text}\n\n\n`;
export const h3 = (text: string) => `### ${text}\n\n\n`;
export const paragraph = (text: string) => `${text}\n\n\n`;

// Text styling
export const bold = (text: string) => `**${text}**\n\n\n`;
export const italic = (text: string) => `*${text}*\n\n\n`;
export const boldItalic = (text: string) => `***${text}***\n\n\n`;
export const strikethrough = (text: string) => `~~${text}~~\n\n\n`;

// Lists
export const bulletList = (items: string[]) => items.map((item) => `- ${item}`).join("\n") + "\n\n\n";
export const numberedList = (items: string[]) =>
  items.map((item, index) => `${index + 1}. ${item}`).join("\n") + "\n\n\n";

// Other elements
export const blockquote = (text: string) => `> ${text}\n\n\n`;
export const code = (text: string) => `\`${text}\`\n\n\n`;
export const codeBlock = (text: string, language = "") => `\`\`\`${language}\n${text}\n\`\`\`\n\n\n`;
export const horizontalRule = () => `---\n\n\n`;

// Links and images
export const link = (text: string, url: string) => `[${text}](${url})`;
export const image = (altText: string, url: string) => `![${altText}](${url})`;

// Tables
export const table = (headers: string[], rows: string[][]) => {
  const headerRow = `| ${headers.join(" | ")} |`;
  const separator = `| ${headers.map(() => "---").join(" | ")} |`;
  const dataRows = rows.map((row) => `| ${row.join(" | ")} |`);

  return `${headerRow}\n${separator}\n${dataRows.join("\n")}\n\n`;
};
