import { FormControl, FormLabel, Heading } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";

interface SectionProps {
  control: Control<HandbookFormData>;
}

export const EmployerEmployeeSection = ({ control }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Employer Employee Relationship</Heading>
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How many hours must someone work to be full-time equivalent (FTE) for insurance?
          </Heading>
        </FormLabel>
        <Controller
          name="fte-hours-q"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              type="number"
              value={field.value?.toString() || ""}
              placeholder="Enter number of hours..."
            />
          )}
        />
      </FormControl>
    </>
  );
};
