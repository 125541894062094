import { FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text, VStack, Input } from "@chakra-ui/react";
import { Control, Controller, UseFormWatch } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";
import { MEDIA_INQUIRY_OPTIONS } from "./constants";

interface SectionProps {
  control: Control<HandbookFormData>;
  setValue: (name: keyof HandbookFormData, value: HandbookFormData[keyof HandbookFormData]) => void;
  watch: UseFormWatch<HandbookFormData>;
}

export const TechSection = ({ control, setValue, watch }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Technology, Internet and Social Media</Heading>

      {/* Cell Phone Policy */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What do you want your cell phone policy to be?
          </Heading>
        </FormLabel>
        <Text fontSize="sm" color="blue.400" mb={4}>
          Specify when and where employees can use their phones during work hours.
        </Text>
        <Controller
          name="cell-phone-policy"
          control={control}
          render={({ field }) => (
            <TextInput {...field} value={field.value || ""} placeholder="Describe your cell phone policy..." />
          )}
        />
      </FormControl>

      {/* Media Inquiry Policy */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How do you want to handle media inquiries?
          </Heading>
        </FormLabel>
        <Controller
          name="media-inquiry-type"
          control={control}
          render={({ field }) => (
            <VStack align="stretch" spacing={2}>
              <RadioGroup
                onChange={(value) => {
                  field.onChange(value);
                  if (value !== "Other") {
                    setValue("media-inquiry-custom", "");
                    setValue("media-inquiry-policy", value);
                  }
                }}
                value={field.value}
                colorScheme="brand"
              >
                <Stack direction="column" spacing={3}>
                  {MEDIA_INQUIRY_OPTIONS.map((option) => (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              {watch("media-inquiry-type") === "Other" && (
                <Controller
                  name="media-inquiry-custom"
                  control={control}
                  render={({ field }) => {
                    // Update the main media-inquiry-policy when custom value changes
                    const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e);
                      setValue("media-inquiry-policy", e.target.value);
                    };

                    return (
                      <Input
                        {...field}
                        onChange={handleCustomChange}
                        placeholder="Enter custom media inquiry policy..."
                        value={field.value || ""}
                      />
                    );
                  }}
                />
              )}
            </VStack>
          )}
        />
      </FormControl>
    </>
  );
};
