import { FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Input, VStack } from "@chakra-ui/react";
import { Control, Controller, UseFormWatch } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";
import { PAYMENT_METHOD_OPTIONS } from "./constants";

interface SectionProps {
  control: Control<HandbookFormData>;
  setValue: (name: keyof HandbookFormData, value: HandbookFormData[keyof HandbookFormData]) => void;
  watch: UseFormWatch<HandbookFormData>;
}

export const PayPoliciesSection = ({ control, setValue, watch }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Pay Policies</Heading>

      {/* Pay Schedule Question */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your pay schedule?
          </Heading>
        </FormLabel>
        <Controller
          name="pay-schedule-q"
          control={control}
          render={({ field }) => (
            <TextInput {...field} value={field.value || ""} placeholder="e.g., Every two weeks on Friday" />
          )}
        />
      </FormControl>

      {/* Tip Credit Question */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Will you be using the "tip credit" to subsidize wages for tipped employees?
          </Heading>
        </FormLabel>
        <Controller
          name="tip-credit-q"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={(val) => onChange(val === "true")} value={value?.toString()} colorScheme="brand">
              <Stack direction="row" spacing={5}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      {/* Payment Method Question */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How do your employees get paid?
          </Heading>
        </FormLabel>
        <VStack align="stretch" spacing={2}>
          <Controller
            name="payment-method-type"
            control={control}
            render={({ field }) => (
              <RadioGroup
                onChange={(value) => {
                  field.onChange(value);
                  if (value !== "Other") {
                    setValue("payment-method-custom", "");
                    setValue("payment-method-q", value);
                  }
                }}
                value={field.value}
                colorScheme="brand"
              >
                <Stack direction="column" spacing={3}>
                  {PAYMENT_METHOD_OPTIONS.map((option) => (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
          {watch("payment-method-type") === "Other" && (
            <Controller
              name="payment-method-custom"
              control={control}
              render={({ field }) => {
                // Update the main payment-method-q when custom value changes
                const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e);
                  setValue("payment-method-q", e.target.value);
                };

                return (
                  <Input
                    {...field}
                    onChange={handleCustomChange}
                    placeholder="Enter custom payment method..."
                    value={field.value || ""}
                  />
                );
              }}
            />
          )}
        </VStack>
      </FormControl>
    </>
  );
};
