import React from "react";

import { VStack, Text, Button, useToast } from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";

interface ResourceCardProps {
  title: string;
  description: string;
  downloadUrl: string;
  fileName: string;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({ title, description, fileName }) => {
  const toast = useToast();

  const handleDownload = async () => {
    try {
      // Get the presigned URL
      const response = await fetch(`/api/files/${fileName}/view`);
      if (!response.ok) {
        throw new Error('Failed to get download URL');
      }
      const data = await response.json();

      // Download the file using the presigned URL
      const fileResponse = await fetch(data.presignedUrl);
      if (!fileResponse.ok) {
        throw new Error('Failed to download file');
      }

      // Get the response as an array buffer
      const arrayBuffer = await fileResponse.arrayBuffer();
      const contentType = fileResponse.headers.get('content-type') || 'application/octet-stream';
      const blob = new Blob([arrayBuffer], { type: contentType });

      // Create download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = title || fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to download file",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  return (
    <VStack
      w="full"
      spacing={[2, 4]}
      p={[4, 8]}
      bg="white"
      borderRadius="xl"
      align="flex-start"
      h="full"
      border="solid"
      borderWidth={2}
      borderColor="blackAlpha.200"
    >
      <Text fontSize="xl" fontWeight="bold">
        {title}
      </Text>
      <Text textAlign="left" color="gray.600" flex={1}>
        {description}
      </Text>
      <Button
        alignSelf="flex-end"
        justifySelf="flex-end"
        color="black"
        variant="solid"
        colorScheme="brand"
        size="sm"
        onClick={handleDownload}
        mt="auto"
        leftIcon={<FiDownload />}
      >
        Download
      </Button>
    </VStack>
  );
}; 