import { useState, useEffect } from "react";

import { VStack, Heading, Button, Box, Text, useToast, HStack, Progress } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import {
  CompanyIntroSection,
  ConductAndPerformanceSection,
  EmployerEmployeeSection,
  EmployeeBenefitsSection,
  PayPoliciesSection,
  PrivacySection,
  PropertyAndIncidentSection,
  TechSection
} from "./form-sections";
import { generateMarkdownContent } from "./generateMarkdown";
import { HandbookFormData } from "./types";
import { useAppStore } from "../../store";
import { AdminQuickFillButton } from "./form-sections/AdminQuickFillButton";

const SECTIONS = [
  "Company Introduction",
  "Employer Employee Relationship",
  "Pay Policies",
  "Employee Benefits",
  "Employee Conduct And Performance",
  "Privacy Policies",
  "Technology, Internet and Social Media",
  "Company Property, Safety and Incident Reporting"
];

const HANDBOOK_FORM_STORAGE_KEY = "handbook_form_data";

interface HandbookBuilderFormProps {
  onHandbookGenerated: (content: string) => void;
  onLoadingStateChange: (isLoading: boolean) => void;
}

export const HandbookBuilderForm = ({ onHandbookGenerated, onLoadingStateChange }: HandbookBuilderFormProps) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const toast = useToast();
  const { control, handleSubmit, setValue, watch } = useForm<HandbookFormData>();

  // Load saved form data on mount
  useEffect(() => {
    const savedData = localStorage.getItem(HANDBOOK_FORM_STORAGE_KEY);
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData) as HandbookFormData;
        Object.entries(parsedData).forEach(([key, value]) => {
          if (value !== undefined) {
            setValue(key as keyof HandbookFormData, value as HandbookFormData[keyof HandbookFormData]);
          }
        });
        toast({
          title: "Form Data Restored",
          description: "Your previous progress has been loaded.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top"
        });
      } catch (error) {
        console.error("Error loading saved form data:", error);
      }
    }
  }, [setValue, toast]);

  // Save form data on changes
  useEffect(() => {
    const subscription = watch((formData) => {
      localStorage.setItem(HANDBOOK_FORM_STORAGE_KEY, JSON.stringify(formData));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data: HandbookFormData) => {
    try {
      onLoadingStateChange(true);
      const markdown = generateMarkdownContent(data);
      // Clear stored form data on successful submission
      localStorage.removeItem(HANDBOOK_FORM_STORAGE_KEY);
      onHandbookGenerated(markdown);
    } catch (error) {
      console.error("Error generating handbook:", error);
      toast({
        title: "Error",
        description: "There was an error generating your handbook. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    } finally {
      onLoadingStateChange(false);
    }
  };

  const handleNext = () => {
    if (currentSectionIndex < SECTIONS.length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
      window.scrollTo(0, 0);
    }
  };

  const isFormComplete = () => {
    return SECTIONS.every((section, index) => isCurrentSectionComplete(index));
  };

  const isCurrentSectionComplete = (sectionIndex?: number) => {
    const requiredFieldsBySection: Record<string, string[]> = {
      "Company Introduction": ["company-culture-q"],
      "Employer Employee Relationship": ["fte-hours-q"],
      "Pay Policies": ["pay-schedule-q", "tip-credit-q", "payment-method-q"],
      "Employee Benefits": [
        "offers-pto-q",
        // Conditional fields based on PTO
        ...(watch("offers-pto-q") ? ["pto-allocation-q", "pto-rollover-q"] : []),
        "health-insurance-policy",
        // Conditional fields based on health insurance
        ...(watch("health-insurance-policy") && !watch("health-insurance-policy").includes("not be offering")
          ? ["insurance-enrollment-dates", "insurance-start-date"]
          : []),
        "employee-discount",
        "closed-holidays",
        "offers-floating-holiday",
        ...(watch("offers-floating-holiday") ? ["floating-holiday-params"] : []),
        "offers-bereavement",
        ...(watch("offers-bereavement") ? ["bereavement-policy"] : []),
        "offers-parental-leave",
        ...(watch("offers-parental-leave") ? ["parental-leave-policy"] : []),
        "offers-401k",
        ...(watch("offers-401k") ? ["401k-policy"] : []),
        "offers-life-insurance",
        ...(watch("offers-life-insurance") ? ["life-insurance-details"] : [])
      ],
      "Employee Conduct And Performance": [
        "performance-evaluations",
        "boh-uniform",
        "foh-uniform",
        "call-in-policy",
        "time-off-request",
        "drug-tests",
        "trade-secret-policy"
      ],
      "Privacy Policies": ["uses-electronic-signatures", "posts-marketing-photos"],
      "Technology, Internet and Social Media": ["cell-phone-policy", "media-inquiry-policy"],
      "Company Property, Safety and Incident Reporting": [
        "uses-company-vehicles",
        "requires-personal-vehicles",
        "incident-reporting",
        "serves-alcohol"
      ]
    };

    const indexToCheck = sectionIndex || currentSectionIndex;
    const currentSection = SECTIONS[indexToCheck];
    const requiredFields = requiredFieldsBySection[currentSection] || [];

    return requiredFields.every((field) => {
      const value = watch(field as keyof HandbookFormData);
      // Check if the value exists and is not an empty string
      // For arrays (like drug-tests), check if it's not empty
      return Array.isArray(value) ? value.length > 0 : value !== undefined && value !== "";
    });
  };

  const renderNavigationButtons = () => (
    <HStack spacing={4} width="100%" justify="space-between" mt={8}>
      <Button onClick={handlePrevious} isDisabled={currentSectionIndex === 0} colorScheme="gray" size="lg">
        Previous
      </Button>
      {currentSectionIndex === SECTIONS.length - 1 ? (
        <Button type="submit" colorScheme="brand" size="lg" isDisabled={!isCurrentSectionComplete()}>
          Generate Handbook
        </Button>
      ) : (
        <Button onClick={handleNext} colorScheme="brand" size="lg" isDisabled={!isCurrentSectionComplete()}>
          Continue
        </Button>
      )}
    </HStack>
  );

  const renderProgressBar = () => (
    <Box width="100%" mb={8}>
      <Progress
        value={((currentSectionIndex + 1) / SECTIONS.length) * 100}
        size="sm"
        colorScheme="brand"
        borderRadius="full"
      />
      <Text textAlign="center" mt={2} fontSize="sm" color="gray.600">
        Section {currentSectionIndex + 1} of {SECTIONS.length}
      </Text>
    </Box>
  );

  const renderCurrentSection = () => {
    const currentSection = SECTIONS[currentSectionIndex];

    switch (currentSection) {
      case "Company Introduction":
        return <CompanyIntroSection control={control} />;
      case "Employer Employee Relationship":
        return <EmployerEmployeeSection control={control} />;
      case "Pay Policies":
        return <PayPoliciesSection control={control} setValue={setValue} watch={watch} />;
      case "Employee Benefits":
        return <EmployeeBenefitsSection control={control} setValue={setValue} watch={watch} />;
      case "Employee Conduct And Performance":
        return <ConductAndPerformanceSection control={control} setValue={setValue} watch={watch} />;
      case "Privacy Policies":
        return <PrivacySection control={control} />;
      case "Technology, Internet and Social Media":
        return <TechSection control={control} setValue={setValue} watch={watch} />;
      case "Company Property, Safety and Incident Reporting":
        return <PropertyAndIncidentSection control={control} />;
      default:
        return <Text>Section under construction</Text>;
    }
  };

  const handleReset = () => {
    localStorage.removeItem(HANDBOOK_FORM_STORAGE_KEY);
    Object.keys(watch()).forEach((key) => {
      setValue(key as keyof HandbookFormData, "");
    });
  };

  return (
    <VStack spacing={8} pt={8} align="stretch" maxW="4xl" mx="auto">
      <HStack justify="space-between" alignItems="start">
        <VStack align="flex-start">
          <Heading size="lg" mb={3}>
            Employee Handbook Builder
          </Heading>
          <Text fontSize="md" color="gray.700">
            Protect your staff and your business in less than 30 minutes.
          </Text>
        </VStack>
        {userInfo?.isAdmin && (
          <HStack p={2} backgroundColor="white" gap={4} border="1px dashed black" rounded="md" height="100%">
            <Button onClick={handleReset}>Reset</Button>
            <AdminQuickFillButton setValue={setValue} />
            <Button colorScheme="brand" onClick={() => onSubmit(watch())} isDisabled={!isFormComplete()}>
              Submit
            </Button>
          </HStack>
        )}
      </HStack>

      {renderProgressBar()}

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <VStack spacing={12} align="stretch">
          {renderCurrentSection()}
          {renderNavigationButtons()}
        </VStack>
      </form>
    </VStack>
  );
};
