import { axiosClient } from "./httpclient-service";

export interface RestaurantInfo {
  id: number;
  user_id: number;
  name: string;
  culture: string;
  created_at: string;
  updated_at: string;
}

export const restaurantService = {
  // TODO: We will need to update this for multiple restaurants
  async getRestaurantInfo(): Promise<RestaurantInfo | null> {
    try {
      const response = await axiosClient.get<RestaurantInfo>(`/restaurant`);
      return response.data;
    } catch (error) {
      console.error("Error fetching restaurant info:", error);
      return null;
    }
  },

  async saveRestaurantInfo(name: string, culture: string): Promise<RestaurantInfo | null> {
    try {
      const response = await axiosClient.post<RestaurantInfo>("/restaurant", {
        name,
        culture
      });
      return response.data;
    } catch (error) {
      console.error("Error saving restaurant info:", error);
      return null;
    }
  }
};
