import { UserInfo } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { getUserInfo } from "../services/api-service";

export function useUserInfo() {
  const { data, isLoading } = useQuery<UserInfo>({
    queryKey: ["userAuthId"],
    queryFn: () => getUserInfo(),
    retry: false
  });

  return { data, isLoading };
}
