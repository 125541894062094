import {
  JobDescriptionInfo,
  GeneratedJobDescription,
  GeneratedJobDescriptionWithInfo,
  CreateJobDescriptionRequest,
  SaveGeneratedJobDescriptionRequest
} from "@shared/job-description-models";

import { axiosClient } from "./httpclient-service";

export const jobDescriptionService = {
  async getPreviousJobDescription(): Promise<JobDescriptionInfo | null> {
    try {
      const response = await axiosClient.get<JobDescriptionInfo>(`/job-description/latest`);
      return response.data;
    } catch (error) {
      console.error("Error fetching previous job description:", error);
      return null;
    }
  },

  async createJobDescription(data: CreateJobDescriptionRequest): Promise<JobDescriptionInfo | null> {
    try {
      const response = await axiosClient.post<JobDescriptionInfo>("/job-description", {
        ...data
      });
      return response.data;
    } catch (error) {
      console.error("Error creating job description:", error);
      return null;
    }
  },

  async saveGeneratedJobDescription({
    id,
    restaurantId,
    jobDescriptionInfoId,
    markdownContent
  }: SaveGeneratedJobDescriptionRequest): Promise<GeneratedJobDescription | null> {
    try {
      const response = await axiosClient.post<GeneratedJobDescription>("/job-description/save-result", {
        id,
        restaurantId,
        jobDescriptionInfoId: jobDescriptionInfoId.toString(),
        markdownContent
      });
      return response.data;
    } catch (error) {
      console.error("Error saving generated job description:", error);
      return null;
    }
  },

  async getGeneratedJobDescription(jobDescriptionInfoId: number): Promise<GeneratedJobDescriptionWithInfo | null> {
    try {
      const response = await axiosClient.get<GeneratedJobDescriptionWithInfo>(
        `/job-description/${jobDescriptionInfoId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching generated job description:", error);
      return null;
    }
  },

  async getAllJobDescriptions(): Promise<GeneratedJobDescriptionWithInfo[]> {
    try {
      const response = await axiosClient.get<GeneratedJobDescriptionWithInfo[]>(`/job-description/all`);
      return response.data;
    } catch (error) {
      console.error("Error fetching completed job descriptions:", error);
      return [];
    }
  }
};
