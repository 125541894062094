import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  Input
} from "@chakra-ui/react";
import { Control, Controller, UseFormWatch } from "react-hook-form";

import TextInput from "../../../components/TextInput";
import { HandbookFormData } from "../types";
import { DRUG_TEST_OPTIONS, TERMINATION_GROUNDS_OPTIONS, TRADE_SECRET_OPTIONS } from "./constants";

interface SectionProps {
  control: Control<HandbookFormData>;
  setValue: (name: keyof HandbookFormData, value: HandbookFormData[keyof HandbookFormData]) => void;
  watch: UseFormWatch<HandbookFormData>;
}

export const ConductAndPerformanceSection = ({ control, setValue, watch }: SectionProps) => {
  return (
    <>
      <Heading size="lg">Employee Conduct And Performance</Heading>

      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How will you be administering performance evaluations?
          </Heading>
        </FormLabel>
        <Text fontSize="sm" color="blue.400" mb={4}>
          Specify frequency (annually, quarterly) or indicate if you won't be conducting them.
        </Text>
        <Controller
          name="performance-evaluations"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value || ""}
              placeholder="Describe your performance evaluation policy..."
            />
          )}
        />
      </FormControl>

      {/* Uniform - Back of House */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your uniform for the Back of the House employees?
          </Heading>
        </FormLabel>
        <Controller
          name="boh-uniform"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value || ""}
              placeholder="Describe the Back of House uniform requirements..."
            />
          )}
        />
      </FormControl>

      {/* Uniform - Front of House */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your uniform for the Front of the House employees?
          </Heading>
        </FormLabel>
        <Controller
          name="foh-uniform"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              value={field.value || ""}
              placeholder="Describe the Front of House uniform requirements..."
            />
          )}
        />
      </FormControl>

      {/* Call-in Policy */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            What is your call-in policy?
          </Heading>
        </FormLabel>
        <Text fontSize="sm" color="blue.400" mb={4}>
          Include details about timing, contact method, and documentation requirements.
        </Text>
        <Controller
          name="call-in-policy"
          control={control}
          render={({ field }) => (
            <TextInput {...field} value={field.value || ""} placeholder="Describe your call-in policy..." />
          )}
        />
      </FormControl>

      {/* Time Off Request */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How do you want people to request time off?
          </Heading>
        </FormLabel>
        <Text fontSize="sm" color="blue.400" mb={4}>
          Specify method of request and advance notice requirements.
        </Text>
        <Controller
          name="time-off-request"
          control={control}
          render={({ field }) => (
            <TextInput {...field} value={field.value || ""} placeholder="Describe your time off request process..." />
          )}
        />
      </FormControl>

      {/* Drug Tests */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Which (if any) drugs tests will you be requiring?
          </Heading>
        </FormLabel>
        <Controller
          name="drug-tests"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CheckboxGroup colorScheme="brand" value={field.value}>
              <VStack align="start" spacing={2}>
                {DRUG_TEST_OPTIONS.map((option) => (
                  <Checkbox
                    key={option}
                    value={option}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...(field.value || []), option]
                        : (field.value || []).filter((v) => v !== option);
                      field.onChange(newValue);
                    }}
                  >
                    {option}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          )}
        />
      </FormControl>

      {/* Grounds for Termination */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            Please select which of these you consider actions that are grounds for immediate termination:
          </Heading>
        </FormLabel>
        <Controller
          name="termination-grounds"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <CheckboxGroup colorScheme="brand" value={field.value}>
              <VStack align="start" spacing={2}>
                {TERMINATION_GROUNDS_OPTIONS.map((option) => (
                  <Checkbox
                    key={option}
                    value={option}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...(field.value || []), option]
                        : (field.value || []).filter((v) => v !== option);
                      field.onChange(newValue);
                    }}
                  >
                    {option}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          )}
        />
      </FormControl>

      {/* Trade Secret Confidentiality */}
      <FormControl>
        <FormLabel fontWeight="bold">
          <Heading size="md" as="span">
            How do you want to handle confidentiality with your business with things like trade secrets?
          </Heading>
        </FormLabel>
        <Controller
          name="trade-secret-type"
          control={control}
          render={({ field }) => (
            <VStack align="stretch" spacing={2}>
              <RadioGroup
                onChange={(value) => {
                  field.onChange(value);
                  if (value !== "Other") {
                    setValue("trade-secret-custom", "");
                    setValue("trade-secret-policy", value);
                  }
                }}
                value={field.value}
                colorScheme="brand"
              >
                <Stack direction="column" spacing={3}>
                  {TRADE_SECRET_OPTIONS.map((option) => (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              {watch("trade-secret-type") === "Other" && (
                <Controller
                  name="trade-secret-custom"
                  control={control}
                  render={({ field }) => {
                    // Update the main trade-secret-policy when custom value changes
                    const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e);
                      setValue("trade-secret-policy", e.target.value);
                    };

                    return (
                      <Input
                        {...field}
                        value={field.value || ""}
                        onChange={handleCustomChange}
                        placeholder="Enter custom trade secret policy..."
                      />
                    );
                  }}
                />
              )}
            </VStack>
          )}
        />
      </FormControl>
    </>
  );
};
