import { Box, IconButton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { LuListTree } from "react-icons/lu";

interface MobileNavProps {
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const MobileNav = ({ isOpen, onToggle, children }: MobileNavProps) => {
  return (
    <Box position="relative">
      <IconButton
        aria-label="Toggle navigation"
        icon={<LuListTree size={24} />}
        position="fixed"
        top={14}
        left={0.5}
        zIndex={10}
        colorScheme="brand"
        onClick={onToggle}
        size="md"
        borderRadius="6"
      />

      <motion.div
        initial={false}
        animate={{
          x: isOpen ? 0 : "100%",
          opacity: isOpen ? 1 : 0,

        }}
        transition={{ duration: 0.3 }}
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          width: "320px",
          background: "white",
          zIndex: 1000,
          boxShadow: "-4px 0 10px rgba(0, 0, 0, 0.1)",
          padding: "1rem"
        }}
      >
        {children}
      </motion.div>

      {isOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="blackAlpha.600"
          zIndex={999}
          onClick={onToggle}
        />
      )}
    </Box>
  );
};

export default MobileNav; 