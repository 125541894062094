import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  HStack
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface MobileWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  buttonText?: string;
}

export const MobileWarningModal = ({
  isOpen,
  onClose,
  title = "Mobile Experience Notice",
  message = "This experience has not been fully optimized for mobile devices yet. While you can still use it, we recommend accessing it on a larger screen for the best experience.",
  buttonText = "I Understand"
}: MobileWarningModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Text>{message}</Text>
        </ModalBody>
        <ModalFooter>
          <HStack w="full" justify="space-between">
            <Button variant="link" onClick={() => navigate("/")}>
              Take Me Home!
            </Button>
            <Button colorScheme="brand" onClick={onClose}>
              {buttonText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
