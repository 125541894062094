import React, { useEffect, useState } from "react";

import { VStack, Box, Text, Image, SimpleGrid, Spinner, HStack, Button } from "@chakra-ui/react";
import { MdPersonSearch } from "react-icons/md";
import { PiGraduationCapFill } from "react-icons/pi";
import { RiFilePaper2Line, RiBookLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { CompletedArtifactCard } from "./components/CompletedArtifactCard";
import { ToolCard } from "./components/ToolCard";
import { GeneratedJobDescriptionWithInfo } from "../../../../../shared/job-description-models";
import { useJobDescription } from "../../hooks/useJobDescription";
import { useSopAnswers } from "../../hooks/useSopAnswers";

type Artifact = GeneratedJobDescriptionWithInfo;

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: sopAnswers, isLoading: isLoadingSop } = useSopAnswers();
  const { completedJobDescriptions, isLoadingCompletedJobDescriptions } = useJobDescription();
  const hasExistingSopAnswers = sopAnswers && Object.keys(sopAnswers).length > 0;
  const [showAllCompletedArtifacts, setShowAllCompletedArtifacts] = useState(false);
  const [showAllTools, setShowAllTools] = useState(false);
  const [completedArtifacts, setCompletedArtifacts] = useState<Artifact[]>([]);

  useEffect(() => {
    if (!completedJobDescriptions) return;
    const artifacts = completedJobDescriptions.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setCompletedArtifacts(artifacts);
  }, [completedJobDescriptions]);

  if (isLoadingSop || isLoadingCompletedJobDescriptions) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  const tools = [
    {
      title: "SOP Builder",
      description: "Create your Standard Operating Procedures in minutes, not hours, with our AI assisted process.",
      icon: <RiFilePaper2Line size={23} />,
      route: "/sop-builder",
      actionText: hasExistingSopAnswers ? "Continue" : "Get Started"
    },
    {
      title: "Job Description Builder",
      description: "Create publish ready job descriptions for exactly what you need.",
      icon: <MdPersonSearch size={25} />,
      route: "/job-builder"
    },
    {
      title: "Handbook Builder",
      description: "Generate a comprehensive employee handbook tailored to your restaurant's needs.",
      icon: <RiBookLine size={24} />,
      route: "/handbook-builder"
    },
    {
      title: "Courses",
      description: "View our courses and learn the skills you need to succeed.",
      icon: <PiGraduationCapFill size={24} />,
      actionText: "View Courses",
      route: "https://up-or-on-the-rocks-hospitality.teachable.com/",
      isExternalLink: true
    }
  ];

  return (
    <VStack w="full" spacing={0} p={0} overflow="hidden" m={0} h="100%">
      <VStack bg="black" w="full" minH={["250px", "400px"]} p={[4, 6]} borderTopRadius={25} justify="center">
        <Image src="/images/rocks-logo.png" alt="Up or On The Rocks Hospitality" maxW={["200px", "300px"]} />
      </VStack>

      <VStack
        w="full"
        border="solid"
        borderWidth={3}
        borderColor="brand.500"
        borderTopWidth={[0, 0]}
        borderBottomRadius={25}
        p={[2, 6]}
        h="100%"
      >
        <VStack w="full" spacing={[4, 8]} align="start" maxW={"container.xl"} h="100%" pt={[0, 10]}>
          <HStack w="full" justify="space-between" align="center">
            <Text fontSize="2xl" fontWeight="bold">
              Helpful Tools
            </Text>
            <Button variant="link" colorScheme="brand" size="sm" onClick={() => setShowAllTools(!showAllTools)}>
              {showAllTools ? "View Less" : "View All"}
            </Button>
          </HStack>

          <SimpleGrid columns={[1, 1, 3]} spacing={[2, 8]} w="full">
            {tools.slice(0, showAllTools ? undefined : 3).map((tool) => (
              <ToolCard
                key={tool.title}
                title={tool.title}
                description={tool.description}
                icon={tool.icon}
                route={tool.route}
                actionText={tool.actionText}
                isExternalLink={tool.isExternalLink}
              />
            ))}
          </SimpleGrid>

          {completedJobDescriptions && completedJobDescriptions.length > 0 && (
            <>
              <HStack w="full" justify="space-between" align="center" mt={4}>
                <Text fontSize="2xl" fontWeight="bold">
                  Recently Completed
                </Text>
                {completedArtifacts.length > 3 && (
                  <Button
                    variant="link"
                    colorScheme="brand"
                    size="sm"
                    onClick={() => setShowAllCompletedArtifacts(!showAllCompletedArtifacts)}
                  >
                    {showAllCompletedArtifacts ? "View Less" : "View All"}
                  </Button>
                )}
              </HStack>

              <SimpleGrid columns={[1, 1, 3]} spacing={[2, 8]} w="full">
                {completedArtifacts.slice(0, showAllCompletedArtifacts ? undefined : 3).map((artifact) => (
                  <CompletedArtifactCard
                    key={artifact.id}
                    title={`${artifact.jobDescriptionInfo.position} Job Description`}
                    createdAt={artifact.createdAt}
                    buttonText="View Job Description"
                    handleClick={() => navigate(`/job-builder/${artifact.jobDescriptionInfo.id}`)}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default HomePage;
