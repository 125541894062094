import { useQuery } from "@tanstack/react-query";

import { axiosClient } from "../services/httpclient-service";

export interface Resource {
  id: number;
  display_name: string;
  description: string;
  stored_file_name: string;
  category: string;
  file_type: string;
}

async function getResources(): Promise<Resource[]> {
  const { data } = await axiosClient.get("/files");
  return data;
}

export function useResources() {
  return useQuery({
    queryKey: ["resources"],
    queryFn: getResources
  });
}
