import { useState, useEffect } from "react";

import { Heading, Center, Spinner, useDisclosure, useMediaQuery } from "@chakra-ui/react";

import { HandbookBuilderForm } from "./HandbookBuilderForm";
import { HandbookReview } from "./HandbookReview";
import { MobileWarningModal } from "../../components/MobileWarningModal";

const HandbookBuilderPage = () => {
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [markdownContent, setMarkdownContent] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (isMobile) {
      onOpen();
    }
  }, [isMobile, onOpen]);

  const handleHandbookGenerated = (content: string) => {
    setMarkdownContent(content);
  };

  const handleStartFresh = () => {
    setMarkdownContent(null);
  };

  // Show loading state during form processing
  if (isFormProcessing) {
    return (
      <Center flexDirection="column" gap={6} height="90vh">
        <Spinner size="xl" speed="0.65s" thickness="4px" color="brand.500" />
        <Heading size="sm">Generating your handbook...</Heading>
      </Center>
    );
  }

  // If we have generated content, show the review page
  if (markdownContent) {
    return <HandbookReview markdownContent={markdownContent} onStartFresh={handleStartFresh} />;
  }

  // Show the form to collect handbook information
  return (
    <>
      <MobileWarningModal isOpen={isOpen} onClose={onClose} />
      <HandbookBuilderForm onHandbookGenerated={handleHandbookGenerated} onLoadingStateChange={setIsFormProcessing} />
    </>
  );
};

export default HandbookBuilderPage;
