import React from "react";

import { VStack, HStack, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface ToolCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  route: string;
  actionText?: string;
  isExternalLink?: boolean;
}

export const ToolCard: React.FC<ToolCardProps> = ({ title, description, icon, route, actionText, isExternalLink }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isExternalLink) {
      window.open(route, "_blank");
    } else {
      navigate(route);
    }
  };

  return (
    <VStack
      w="full"
      spacing={[2, 4]}
      p={[4, 8]}
      bg="white"
      borderRadius="xl"
      cursor="pointer"
      _hover={{ transform: "translateY(-2px)", transition: "transform 0.2s" }}
      align="flex-start"
      h="full"
      border="solid"
      borderWidth={2}
      borderColor="blackAlpha.200"
      onClick={handleClick}
    >
      <HStack gap={2}>
        {icon}
        <Text fontSize="xl" fontWeight="bold">
          {title}
        </Text>
      </HStack>
      <Text textAlign="left" color="gray.600">
        {description}
      </Text>
      <Button
        alignSelf="flex-end"
        justifySelf="flex-end"
        color="black"
        variant="solid"
        colorScheme="brand"
        size="sm"
        onClick={handleClick}
        mt="auto"
      >
        {actionText || "Get Started"}
      </Button>
    </VStack>
  );
};
