import { useEffect } from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  VStack,
  Heading,
  Button,
  Box,
  FormControl,
  FormLabel,
  Text,
  Divider,
  useToast,
  HStack
} from "@chakra-ui/react";
import {
  JobFormData,
  DEFAULT_POSITIONS,
  DEFAULT_RESPONSIBILITIES,
  DEFAULT_REQUIRED_QUALIFICATIONS,
  DEFAULT_PREFERRED_QUALIFICATIONS,
  DEFAULT_BENEFITS,
  JobDescriptionInfo,
  CreateJobDescriptionRequest
} from "@shared/job-description-models";
import { useForm, Controller } from "react-hook-form";

import OtherMultiSelect from "../../components/MultiSelect/OtherMultiSelect";
import TextInput from "../../components/TextInput";
import { useJobDescription } from "../../hooks/useJobDescription";
import { useJobDescriptionGenAI } from "../../hooks/useJobDescriptionGenAI";
import { useRestaurantInfo } from "../../hooks/useRestaurantInfo";
import { RestaurantInfo } from "../../services/restaurant-service";
import { useAppStore } from "../../store";

interface JobDescriptionBuilderFormProps {
  restaurantInfo: RestaurantInfo | null;
  previousJobDescription: JobDescriptionInfo | null;
  onJobDescriptionGenerated: (jobDescriptionId: number) => void;
  onLoadingStateChange: (isLoading: boolean) => void;
}

export const JobDescriptionBuilderForm = ({
  restaurantInfo,
  previousJobDescription,
  onJobDescriptionGenerated,
  onLoadingStateChange
}: JobDescriptionBuilderFormProps) => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const toast = useToast();

  const { saveRestaurantInfo, isSavingRestaurantInfo } = useRestaurantInfo();
  const { createJobDescription, isCreatingJobDescription } = useJobDescription();
  const { generateJobDescription } = useJobDescriptionGenAI();

  const { control, handleSubmit, setValue } = useForm<JobFormData>({
    defaultValues: {
      name: restaurantInfo?.name || "",
      culture: restaurantInfo?.culture || "",
      position: [],
      responsibilities: [],
      requiredQualifications: [],
      preferredQualifications: [],
      benefits: [],
      howToApply: previousJobDescription?.howToApply || "",
      additionalInfo: ""
    }
  });

  // Pre-populate form with restaurant info and previous job description
  useEffect(() => {
    if (restaurantInfo) {
      setValue("name", restaurantInfo.name);
      setValue("culture", restaurantInfo.culture);
    }
  }, [restaurantInfo, setValue]);

  useEffect(() => {
    if (previousJobDescription) {
      setValue("howToApply", previousJobDescription.howToApply);
    }
  }, [previousJobDescription, setValue]);

  const onSubmit = async (data: JobFormData) => {
    // Validate required fields and collect error messages
    const errorMessages: string[] = [];

    if (!data.name) {
      errorMessages.push("Please enter your restaurant's name");
    }
    if (!data.culture) {
      errorMessages.push("Please add culture, mission or core values");
    }
    if (!data.position || data.position.length === 0) {
      errorMessages.push("Please select a job position");
    }
    if (!data.responsibilities || data.responsibilities.length === 0) {
      errorMessages.push("Please add at least one job responsibility");
    }
    if (!data.requiredQualifications || data.requiredQualifications.length === 0) {
      errorMessages.push("Please add at least one required qualification");
    }
    if (!data.howToApply) {
      errorMessages.push("Please add instructions on how to apply.");
    }

    if (errorMessages.length > 0) {
      toast({
        position: "top",
        status: "error",
        duration: 5000,
        isClosable: true,
        render: () => (
          <VStack
            align="flex-start"
            spacing={2}
            p={4}
            mt={12}
            borderWidth={2}
            borderRadius="lg"
            borderColor="blackAlpha.800"
            bg="red.200"
            width="500px"
          >
            <HStack justify="space-between" align="center" w="100%">
              <Heading size="sm">Missing Required Information</Heading>
              <CloseIcon cursor="pointer" onClick={() => toast.closeAll()} boxSize={3} />
            </HStack>
            <Divider />
            <VStack align="flex-start" spacing={2}>
              {errorMessages.map((message) => (
                <Text key={message}>{message}</Text>
              ))}
            </VStack>
          </VStack>
        )
      });
      return;
    }

    try {
      onLoadingStateChange(true);
      // Save restaurant info if it's new or different
      let restaurantResult: RestaurantInfo | null = null;
      if (!restaurantInfo || restaurantInfo.name !== data.name || restaurantInfo.culture !== data.culture) {
        restaurantResult = await saveRestaurantInfo({
          name: data.name,
          culture: data.culture
        });

        if (!restaurantResult) {
          throw new Error("Failed to save restaurant info");
        }
      }

      const restaurantId = restaurantInfo?.id || restaurantResult?.id;
      if (!restaurantId) {
        throw new Error("Restaurant ID is required");
      }

      // Create job description entry
      const createRequest: CreateJobDescriptionRequest = {
        restaurantId,
        position: data.position[0],
        responsibilities: data.responsibilities,
        requiredQualifications: data.requiredQualifications,
        preferredQualifications: data.preferredQualifications,
        benefits: data.benefits,
        howToApply: data.howToApply,
        additionalInfo: data.additionalInfo
      };

      const jobDescResult = await createJobDescription(createRequest);

      if (jobDescResult) {
        await generateJobDescription(data, jobDescResult.id, restaurantId);
        onJobDescriptionGenerated(jobDescResult.id);
      } else {
        throw new Error("Failed to create job description");
      }
    } catch (error) {
      // Error will be handled by the hooks' error handlers
      console.error("Error creating job description:", error);
    } finally {
      onLoadingStateChange(false);
    }
  };

  const quickFillAndGenerate = async () => {
    onLoadingStateChange(true);
    try {
      const getRandomItems = (items: string[], count: number) => {
        return [...items].sort(() => Math.random() - 0.5).slice(0, count);
      };

      const data: JobFormData = {
        name: "Legend Pizza",
        position: [DEFAULT_POSITIONS[Math.floor(Math.random() * DEFAULT_POSITIONS.length)]],
        culture:
          "A fun and fast-paced restaurant that thrives off the creativity of our community and the competitive culture of Big 10 university sports.",
        responsibilities: getRandomItems(DEFAULT_RESPONSIBILITIES, 3),
        requiredQualifications: getRandomItems(DEFAULT_REQUIRED_QUALIFICATIONS, 3),
        preferredQualifications: getRandomItems(DEFAULT_PREFERRED_QUALIFICATIONS, 3),
        benefits: getRandomItems(DEFAULT_BENEFITS, 3),
        howToApply: "Please stop by the restaurant and talk with a manager.",
        additionalInfo: "We are accomodating to student schedules."
      };

      await onSubmit(data);
    } finally {
      onLoadingStateChange(false);
    }
  };

  return (
    <VStack spacing={8} pt={8} align="stretch" maxW="4xl" mx="auto">
      <Box>
        <Heading size="lg" mb={3}>
          Job Description Builder
        </Heading>
        <Text fontSize="sm" color="gray.700">
          Seven simple questions and we'll generate a custom job description that you can use in whatever way you want.
        </Text>
        {userInfo?.isAdmin && (
          <Button mt={4} onClick={() => quickFillAndGenerate()}>
            Quick Fill And Generate (Admin Only)
          </Button>
        )}
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <VStack spacing={12} align="stretch">
          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Restaurant Name
              </Heading>
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value}
                  placeholder="What do you want people to see in the job description?"
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                What is your restaurant's culture, mission or core value(s)?
              </Heading>
            </FormLabel>
            <Text fontSize="sm" color="blue.400" mb={4}>
              If you don't know this yet, just keep it simple. Think of what you want your restaurant to be known for
              with a few words or descriptions.
            </Text>
            <Controller
              name="culture"
              control={control}
              render={({ field }) => (
                <TextInput {...field} value={field.value} placeholder="Describe your restaurant's culture..." />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                What is the job position you are hiring for?
              </Heading>
            </FormLabel>
            <Text fontSize="sm" color="blue.400" mb={4}>
              Choose only one.
            </Text>
            <Controller
              name="position"
              control={control}
              render={({ field }) => (
                <OtherMultiSelect
                  selectedItems={field.value}
                  defaultItems={DEFAULT_POSITIONS}
                  onButtonClick={(item) => {
                    if (field.value.includes(item)) {
                      setValue(
                        "position",
                        field.value.filter((i) => i !== item)
                      );
                    } else {
                      setValue("position", [item]);
                    }
                  }}
                  onAddClick={(item: string) => setValue("position", [...field.value, item])}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Add the job responsibilities you want included for the role:
              </Heading>
            </FormLabel>
            <Controller
              name="responsibilities"
              control={control}
              render={({ field }) => (
                <OtherMultiSelect
                  selectedItems={field.value}
                  defaultItems={DEFAULT_RESPONSIBILITIES}
                  onButtonClick={(item) => {
                    const newResponsibilities = field.value.includes(item)
                      ? field.value.filter((i) => i !== item)
                      : [...field.value, item];
                    setValue("responsibilities", newResponsibilities);
                  }}
                  onAddClick={(item: string) => setValue("responsibilities", [...field.value, item])}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Select the <em>required</em> qualifications you want for the role:
              </Heading>
            </FormLabel>
            <Controller
              name="requiredQualifications"
              control={control}
              render={({ field }) => (
                <OtherMultiSelect
                  selectedItems={field.value}
                  defaultItems={DEFAULT_REQUIRED_QUALIFICATIONS}
                  onButtonClick={(item) => {
                    const newQualifications = field.value.includes(item)
                      ? field.value.filter((i) => i !== item)
                      : [...field.value, item];
                    setValue("requiredQualifications", newQualifications);
                  }}
                  onAddClick={(item: string) => setValue("requiredQualifications", [...field.value, item])}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Select the <em>preferred</em> qualifications you want for the role:
              </Heading>
            </FormLabel>
            <Controller
              name="preferredQualifications"
              control={control}
              render={({ field }) => (
                <OtherMultiSelect
                  selectedItems={field.value}
                  defaultItems={DEFAULT_PREFERRED_QUALIFICATIONS}
                  onButtonClick={(item) => {
                    const newQualifications = field.value.includes(item)
                      ? field.value.filter((i) => i !== item)
                      : [...field.value, item];
                    setValue("preferredQualifications", newQualifications);
                  }}
                  onAddClick={(item: string) => setValue("preferredQualifications", [...field.value, item])}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Select any of the benefits that your company offers:
              </Heading>
            </FormLabel>
            <Controller
              name="benefits"
              control={control}
              render={({ field }) => (
                <OtherMultiSelect
                  selectedItems={field.value}
                  defaultItems={DEFAULT_BENEFITS}
                  onButtonClick={(item) => {
                    const newBenefits = field.value.includes(item)
                      ? field.value.filter((i) => i !== item)
                      : [...field.value, item];
                    setValue("benefits", newBenefits);
                  }}
                  onAddClick={(item: string) => setValue("benefits", [...field.value, item])}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                How do they apply?
              </Heading>
            </FormLabel>
            <Controller
              name="howToApply"
              control={control}
              render={({ field }) => (
                <TextInput {...field} value={field.value} placeholder="Describe the application process..." />
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              <Heading size="md" as="span">
                Anything else you want to add that we missed?
              </Heading>
            </FormLabel>
            <Controller
              name="additionalInfo"
              control={control}
              render={({ field }) => (
                <TextInput {...field} value={field.value} placeholder="Add any additional information..." />
              )}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="brand"
            size="lg"
            isLoading={isCreatingJobDescription || isSavingRestaurantInfo}
            loadingText="Generating..."
            alignSelf="flex-end"
          >
            Generate Job Description
          </Button>
        </VStack>
      </form>
    </VStack>
  );
};
