import React, { useEffect, useRef, useMemo } from "react";

import { VStack, Heading, Text } from "@chakra-ui/react";
import { Question } from "@shared/models";

import { SectionContent } from "./SectionContent";
import { ShareButtons } from "./ShareButtons";
import { workflow } from "../../../constants/workflow";
import { usePdfGeneration } from "../../../hooks/usePdfGeneration";
import { useReportAnswersMutation } from "../../../hooks/useSopAnswerMutation";
import { useSopAnswers } from "../../../hooks/useSopAnswers";
import { useVisibilityFilters } from "../../../hooks/useVisibilityFilters";
import { useAppStore } from "../../../store";

export interface FilteredSection {
  id: string;
  name: string;
  urlSlug: string;
  pages: FilteredPage[];
}

export interface FilteredPage {
  id: string;
  name: string;
  urlSlug: string;
  questions: Question[];
}

const ReviewSopPage: React.FC = () => {
  const { session } = useAppStore();
  const { data: sopAnswers } = useSopAnswers();
  const printRef = useRef<HTMLDivElement>(null);
  const reportAnswersMutation = useReportAnswersMutation();
  const { updateSessionFromAnswers } = useAppStore();
  const { getReportSections } = useVisibilityFilters();

  const filteredSections = useMemo(() => getReportSections(workflow.sections), [workflow.sections, getReportSections]);
  const { generateSopPdf } = usePdfGeneration(filteredSections, session);

  useEffect(() => {
    if (sopAnswers) {
      updateSessionFromAnswers(sopAnswers);
    }
  }, [sopAnswers]);

  const handleEdit = (questionToUpdate: { questionId: string; reportAnswer: string }) => {
    reportAnswersMutation.mutate([questionToUpdate]);
  };

  const handleGeneratePdf = async (previewInBrowser: boolean) => {
    await generateSopPdf(previewInBrowser);
  };

  return (
    <VStack ref={printRef} spacing={[2, 4]} alignItems="flex-start" w="full" bg="white" borderRadius="3xl" p={[2, 4]} h="full">
      <Heading size="xl" pl={[5, 0]}>State of Michigan SOP</Heading>

      <VStack w="full" alignItems="flex-start" className="hide-print" spacing={[1, 4]}>
        <Text size="md">Take a moment to review and download your SOP.</Text>
        <ShareButtons
          onDownload={() => handleGeneratePdf(false)}
          onViewPdf={() => handleGeneratePdf(true)}
          type="secondary"
        />
      </VStack>

      <VStack w="full" className="print-content" h="full">
        <SectionContent sections={filteredSections} session={session} onEdit={handleEdit} />
      </VStack>

      <VStack w="full" className="hide-print">
        <ShareButtons
          onDownload={() => handleGeneratePdf(false)}
          onViewPdf={() => handleGeneratePdf(true)}
          type="primary"
        />
      </VStack>
    </VStack>
  );
};

export default ReviewSopPage;
